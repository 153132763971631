import api from 'api';
import { getYear } from 'date-fns';
import { TGeneralVacation } from 'models/GeneralVacation';
import { Pagination, SortingOrder } from 'models/types';
import { create } from 'zustand';
import { TGeneralVacationsParams } from '../types/TGeneralVacationsParams';

interface GeneralVacationsState {
  isLoading: boolean;
  vacations: TGeneralVacation[];
  pagination: Pagination;
  params: TGeneralVacationsParams;
  error?: string;
  getVacations: (params?: Partial<TGeneralVacationsParams>) => void;
}

const useGeneralVacationsStore = create<GeneralVacationsState>()((set, get) => ({
  isLoading: false,
  isRemovingDocument: false,
  isUpdatingDocument: false,
  vacations: [],
  params: {
    limit: 5,
    page: 1,
    filter: {
      year: getYear(new Date()),
    },
    search: undefined,
    sort: { startDate: SortingOrder.DESCENDING },
  },
  pagination: {
    totalItems: 0,
    currentPage: 0,
    totalPages: 0,
    pageSize: 5,
  },
  getVacations: async (params?: Partial<TGeneralVacationsParams>) => {
    try {
      set({ isLoading: true });
      let finalParams: TGeneralVacationsParams;

      if (params) {
        finalParams = { ...get().params, ...params };
        set({ params: finalParams });
      } else {
        finalParams = get().params;
      }

      const { data } = await api.admin.generalVacations.getVacations(finalParams);
      const { items, ...pagination } = data;
      set({ isLoading: false, vacations: items, pagination });
    } catch (err) {
      set({ isLoading: false });
    }
  },
}));

export default useGeneralVacationsStore;

import React, { FC } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import './EmployeeCard.styles.scss';
import { Avatar } from 'components/Avatar';

type EmployeeCardProps = {
  className?: string;
  employeeId?: string;
  imageUrl?: string;
  firstName: string;
  lastName: string;
};

const EmployeeCard: FC<EmployeeCardProps> = (props) => {
  const { className, employeeId, imageUrl, firstName, lastName } = props;
  const classes = classNames('nce-employee-card', className);

  return (
    <Link to={`/employees/manage-employees/${employeeId}`} className={classes}>
      <div className='nce-employee-card__image'>
        <Avatar
          imageUrl={imageUrl || 'images/userDefault.png'}
          alt={`${firstName} ${lastName}`}
          size='md'
        />
      </div>
      <div className='nce-employee-card__info'>
        <p className='nce-employee-card__firstName'>{firstName}</p>
        <p className='nce-employee-card__lastName'>{lastName}</p>
      </div>
    </Link>
  );
};

export default EmployeeCard;

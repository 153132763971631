import { PenIcon } from 'assets';
import { Button, EditNonWorkingDaysModal } from 'components';
import { useModalState } from 'hooks';
import { EmployeeNonWorkingDays } from 'models/NonWorkingDays';
import { snackbar } from 'modules';
import useEmployeeVacationsStore from 'pages/NonWorkingDaysPage/stores/useEmployeeVacationsStore';
import React, { useCallback } from 'react';

type EditWorkingDaysProps = {
  data: EmployeeNonWorkingDays;
};
const EditWorkingDays: React.FC<EditWorkingDaysProps> = ({ data }) => {
  const { isOpen, closeModal, openModal } = useModalState();

  const { updateNonWorkingDays } = useEmployeeVacationsStore();

  const onEditSuccess = useCallback(() => {
    snackbar.show({
      message: 'You have successfully changed non working days for an employee.',
      type: 'success',
    });
    closeModal();
  }, [closeModal]);

  const onEditError = useCallback((errorMessage: string) => {
    const finalMessage = errorMessage.replace('vacationDaysCurrentYear', 'No of days');
    snackbar.show({ message: finalMessage, type: 'error' });
  }, []);

  const handleEdit = useCallback(
    (value: number) => {
      return updateNonWorkingDays(data.employeeId, value, onEditSuccess, onEditError);
    },
    [onEditError, onEditSuccess, data],
  );

  return (
    <div>
      <Button
        disabled={!data.startDate}
        onClick={openModal}
        className='edit-button'
        type='button'
        variant='icon'
      >
        <PenIcon />
      </Button>
      <EditNonWorkingDaysModal
        data={data}
        onSubmit={handleEdit}
        onClose={closeModal}
        isOpen={isOpen}
      />
    </div>
  );
};

export default EditWorkingDays;

import { yupResolver } from '@hookform/resolvers/yup';
import { useAxios, useModalState } from 'hooks';
import { Salary } from 'models/Salary';
import { ErrorType } from 'models/types';
import { User } from 'models/User';
import { snackbar } from 'modules';
import React, { useCallback, useContext, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { MSG_REQUIRED_FIELD } from 'validation';
import * as Yup from 'yup';
import { SalaryVariablesCard, SalaryVariablesEditModal } from './components';
import parseAxiosError from 'utils/parseAxiosError';
import useGlobalMiltipliersStore from 'store/useGlobalMiltipliersStore';
import EmployeeContext from 'pages/EmployeeProfilePage/context/EmploeeContext';
import useAdminEmploymentStore from 'pages/EmployeeProfilePage/store/useAdminEmploymentStore';

import './EmployeeSalaryVariables.styles.scss';

export type UpdateMultiplierValue = {
  multiplierId: string;
};

const validation = Yup.object().shape({
  multiplierId: Yup.string().required(MSG_REQUIRED_FIELD),
});

const EmployeeSalaryVariables = () => {
  const { employee, updateEmployee } = useContext(EmployeeContext);
  const { updateDocumentCache } = useAdminEmploymentStore();
  const { id: employeeId, multiplier, lastContract } = employee || {};

  const userSalaryVariables = useMemo(() => {
    if (!multiplier || !lastContract)
      return {
        multiplier: '',
        multiplierId: '',
        grossSalary: { amount: 0, currency: 'RSD' } as Salary,
      };
    return {
      multiplier: multiplier.name,
      multiplierId: multiplier.id,
      grossSalary: lastContract.grossSalary,
    };
  }, [multiplier, lastContract]);

  const { isOpen: isEditModalOpen, openModal, closeModal } = useModalState();

  const { multipliers } = useGlobalMiltipliersStore();

  const methods = useForm<UpdateMultiplierValue>({
    mode: 'onTouched',
    resolver: yupResolver(validation),
    values: {
      multiplierId: userSalaryVariables.multiplierId,
    },
  });

  const { request: editUserMultiplier } = useAxios<User, ErrorType, UpdateMultiplierValue>({
    url: `users/${employeeId}`,
    method: 'PATCH',
    onResponse: (response) => {
      const { data: user } = response;
      snackbar.show({
        message: 'Multiplier successfully changed.',
        type: 'success',
      });
      updateEmployee(user);
      if (user.lastContract)
        updateDocumentCache(user.lastContract.id, {
          multiplierId: user.multiplier?.id,
          grossSalary: user.lastContract.grossSalary,
        });
      closeEditMultiplierModal();
    },
    onError: (error) => {
      const errorMessage = parseAxiosError(error) || '';
      methods.setError('root', error);
      snackbar.show({ message: errorMessage, type: 'error' });
    },
  });

  const changeMultiplier = useCallback(
    (multiplier: UpdateMultiplierValue) => {
      return editUserMultiplier({ payload: multiplier });
    },
    [editUserMultiplier],
  );

  const closeEditMultiplierModal = useCallback(() => {
    closeModal();
    methods.reset();
  }, [closeModal, methods]);

  return (
    <>
      <SalaryVariablesCard
        grossSalary={userSalaryVariables.grossSalary}
        multiplierName={userSalaryVariables.multiplier}
        onEditButtonClicked={openModal}
      />

      <SalaryVariablesEditModal
        isOpen={isEditModalOpen}
        onRequestClose={closeEditMultiplierModal}
        methods={methods}
        multipliers={multipliers}
        onSubmit={changeMultiplier}
      />
    </>
  );
};

export default EmployeeSalaryVariables;

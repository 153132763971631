import React, { useContext } from 'react';
import { SectionBlock } from 'components/layout/SectionBlock';
import InviteEmployee from '../InviteEmployee';
import { Avatar, Input } from 'components';

import './EmployeePersonalInformation.styles.scss';
import EmployeeContext from 'pages/EmployeeProfilePage/context/EmploeeContext';
import { Link } from 'react-router-dom';
import { APP_URI } from 'config';
import { BackArrow } from 'assets';

const EmployeePersonalInformation = () => {
  const { employee } = useContext(EmployeeContext);

  const { firstName, lastName, email, phoneNumber, profileImageUrl, hasAcceptedInvite, id } =
    employee || {};

  return (
    <div>
      <Link to={APP_URI.employees} className='ne-personal-information__link'>
        <BackArrow />
        Go back
      </Link>
      <SectionBlock
        title='Personal information'
        asideFromTitle={!hasAcceptedInvite ? <InviteEmployee userId={id} /> : undefined}
      >
        <div className='ne-personal-information'>
          <div className='ne-personal-information__left'>
            <Avatar imageUrl={profileImageUrl} alt='employee-photo' />
          </div>
          <div className='ne-personal-information__right'>
            <Input name='firstName' label='First name' value={firstName} readOnly />
            <Input name='lastName' label='Last name' value={lastName} readOnly />
            <Input name='email' label='Email' value={email} readOnly />
            <Input
              name='phoneNumber'
              label='Phone number'
              value={phoneNumber ? phoneNumber.refferenceNumber + phoneNumber.number : ''}
              readOnly
            />
          </div>
        </div>
      </SectionBlock>
    </div>
  );
};

export default EmployeePersonalInformation;

import { FormProvider, useForm } from 'react-hook-form';
import { FieldWrapper } from 'components/FieldWrapper';
import { InputProps } from 'components/Input/Input.component';
import { PasswordField } from 'components/PasswordField';
import { Button } from 'components/Button';
import React, { useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { useAxios } from 'hooks';
import {
  LOWERCASE_REGEX,
  MSG_REQUIRED,
  NUMBER_REGEX,
  SPECIAL_CHARACTER_REGEX,
  UPPERCASE_REGEX,
} from 'validation';
import { customResolver } from '../PersonalInformation/PersonalInformation.component';
import { useAuth } from 'context';
import { snackbar } from 'modules';
import './ChangePassword.styles.scss';

type ChangePasswordForm = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

const validation = Yup.object().shape({
  currentPassword: Yup.string().required(MSG_REQUIRED),
  newPassword: Yup.string()
    .notOneOf([Yup.ref('currentPassword')], 'New password cant be the same ')
    .min(8, 'Password must be at least 8 characters')
    .matches(LOWERCASE_REGEX, 'Password must contain at least one lowercase letter')
    .matches(UPPERCASE_REGEX, 'Password must contain at least one uppercase letter')
    .matches(SPECIAL_CHARACTER_REGEX, 'Password must contain at least one special character')
    .matches(NUMBER_REGEX, 'Password must contain at least one number'),
  confirmPassword: Yup.string().oneOf([Yup.ref('newPassword')], 'Passwords must match'),
});

const initialValues = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
};

const ChangePassword = () => {
  const { setToken } = useAuth();
  const methods = useForm<ChangePasswordForm>({
    mode: 'onChange',
    defaultValues: initialValues,
    resolver: customResolver(validation),
  });

  const { loading: loadingPassword, request: sendPasswordChangeRequest } = useAxios({
    url: '/users/password/change',
    method: 'POST',
    onResponse: () => {
      setToken(null);
      snackbar.show({
        message: 'Please log in now with your new password',
        type: 'success',
        timeout: 2000,
      });
    },
    onError: () => {
      methods.setError('currentPassword', {
        message: "This password doesn't match your current password",
      });
    },
  });

  const handleSubmit = useCallback(
    (data: ChangePasswordForm) => {
      sendPasswordChangeRequest({
        payload: {
          oldPassword: data.currentPassword,
          newPassword: data.newPassword,
        },
      });
    },
    [sendPasswordChangeRequest],
  );

  const buttonDisabled = useMemo(
    () => !methods.formState.isDirty || !methods.formState.isValid,
    [methods.formState.isDirty, methods.formState.isValid],
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)} className='change-password'>
        <div className='change-password__inputs'>
          <FieldWrapper<InputProps>
            fieldComponent={PasswordField}
            name='currentPassword'
            label='Current password'
            type='password'
            asterix
          />
          <FieldWrapper<InputProps>
            fieldComponent={PasswordField}
            name='newPassword'
            label='New password'
            type='password'
            disabled={methods.watch('currentPassword') === ''}
            asterix
          />
          <FieldWrapper<InputProps>
            fieldComponent={PasswordField}
            name='confirmPassword'
            label='Confirm password'
            type='password'
            disabled={methods.watch('currentPassword') === ''}
            asterix
          />
        </div>
        <Button type='submit' disabled={buttonDisabled} loading={loadingPassword}>
          Save
        </Button>
      </form>
    </FormProvider>
  );
};

export default ChangePassword;

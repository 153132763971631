import { AddIcon } from 'assets';
import { Button, Modal } from 'components';
import { useAxios, useModalState } from 'hooks';
import React from 'react';
import parseAxiosError from 'utils/parseAxiosError';
import { AxiosError } from 'axios';
import { snackbar } from 'modules';

import './InviteEmployee.styles.scss';

type InviteEmployeeProps = {
  userId?: string;
};

const InviteEmployee: React.FC<InviteEmployeeProps> = ({ userId }) => {
  const {
    isOpen: inviteModalOpen,
    openModal: openInviteModal,
    closeModal: closeInviteModal,
  } = useModalState();

  /**
   * INVITE EMPLOYEE AXIOS
   */
  const { loading: loadingInviteEmployee, request: inviteEmployee } = useAxios({
    url: `/users/invite/${userId}`,
    method: 'POST',
    onResponse: () => {
      snackbar.show({ message: 'Email sent', type: 'success' });
      closeInviteModal();
    },
    onError: (error) => {
      const errorMessage = parseAxiosError(error as AxiosError<any, any>) || '';
      snackbar.show({ message: errorMessage, type: 'error' });
    },
  });

  return (
    <>
      <Button onClick={openInviteModal}>
        <AddIcon />
        Invite
      </Button>

      <Modal
        isOpen={inviteModalOpen}
        onRequestClose={closeInviteModal}
        className='ne-invite-employee-modal'
        hasCloseIcon
      >
        <div className='ne-invite-employee-modal__content'>
          <h2>Invite employee</h2>
          <p>
            Invite your newly created employee by sending them an email.
            <br />
            Email contains a link to the login page.
          </p>
          <Button loading={loadingInviteEmployee} onClick={() => inviteEmployee({})}>
            Invite employee
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default InviteEmployee;

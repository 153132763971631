import { SearchIcon } from 'assets';
import { Input } from 'components';
import Table from 'components/Table';
import { SectionBlock } from 'components/layout/SectionBlock';
import useMultipliersStore from 'pages/SettingsPage/store/useMultipliersStore';
import React, { useCallback, useEffect, useState } from 'react';
import useMultipliersTableColumns from './hooks/useMultipliersTableColumns';
import TablePagination from 'components/TablePagination';
import useDebounce from 'hooks/useDebounce';
import { AddMultiplier } from './components/AddMultiplier';

import './MulitpliersSettings.styles.scss';

const MulitpliersSettings = () => {
  const [searchString, setSearchString] = useState('');
  const { getMultipliers, multipliers, pagination, isLoading, applyAndSearch, params } =
    useMultipliersStore();

  useEffect(() => {
    getMultipliers();
    onPageChange(1);
  }, []);

  useEffect(() => {
    if (multipliers.length === 0 && searchString === '' && pagination.currentPage !== 1)
      onPageChange(1);
  }, [multipliers]);

  const columns = useMultipliersTableColumns();

  const onPageChange = useCallback((page: number) => {
    applyAndSearch({ page });
  }, []);

  const onSearchStringChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
  }, []);

  const debouncedSearchString = useDebounce(searchString, 500);

  useEffect(() => {
    applyAndSearch({ search: debouncedSearchString });
  }, [debouncedSearchString]);

  return (
    <SectionBlock
      title='Multipliers'
      loading={isLoading}
      asideFromTitle={<AddMultiplier onMultiplierAdded={getMultipliers} />}
      className='multipliers-page'
    >
      <Input
        prefixNode={<SearchIcon />}
        value={searchString}
        onChange={onSearchStringChange}
        placeholder='Search for a multiplier...'
        className={`search-input multipliers-page__search`}
      />
      <div className='multipliers-page__table-container'>
        <Table
          data={multipliers}
          prepareData={columns}
          noDataMessage={
            multipliers.length === 0 && debouncedSearchString !== ''
              ? 'There are no multipliers under that name.'
              : 'There are no multipliers added yet.'
          }
          hasScrollbar
        />
      </div>

      {pagination?.totalItems > 0 && (
        <TablePagination
          totalPages={pagination?.totalPages}
          currentPage={pagination?.currentPage}
          onPageChange={onPageChange}
          totalItems={pagination?.totalItems}
          pageSize={params.limit}
        />
      )}
    </SectionBlock>
  );
};

export default MulitpliersSettings;

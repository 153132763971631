const USER_BASE_PATH = '/users';
const USER_PASSWORD_PATH = `${USER_BASE_PATH}/password`;
// const USER_IMAGE_PATH = `${USER_BASE_PATH}/image`;

const API_PATH = {
  LOGIN: `${USER_BASE_PATH}/login`,
  FORGOT_PASSWORD: `${USER_PASSWORD_PATH}/forgot`,
  RESET_PASSWORD: `${USER_PASSWORD_PATH}/reset`,
  CURRENT_USER: `${USER_BASE_PATH}/current`,
  USERS: `${USER_BASE_PATH}`,
};

export { API_PATH };

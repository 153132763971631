import { Button } from 'components/Button';
import { EditNonWorkingDaysValue } from 'components/EditNonWorkingDaysModal/EditNonWorkingDaysModal.component';
import { FieldWrapper } from 'components/FieldWrapper';
import Input, { InputProps } from 'components/Input/Input.component';
import { NonWorkingDays } from 'models/NonWorkingDays';
import React, { FC, useEffect, useState, JSX } from 'react';
import { FormProvider, UseFormReturn, useForm } from 'react-hook-form';
import ProgressCircle from 'components/ProgressCircle/ProgressCircle.component';

import './EditNonWorkingDaysForm.styles.scss';

type EditNonWorkingDaysFormProps = {
  employee: string | JSX.Element;
  nonWorkingDays: NonWorkingDays;
  isLoading?: boolean;
  onSubmit: (noOfDays: EditNonWorkingDaysValue) => void;
  closeModal: () => void;
  methods: UseFormReturn<EditNonWorkingDaysValue, any, undefined>;
  variant?: 'modal' | 'standard';
  isEditMode?: boolean;
  isContentOpen?: boolean;
};

const EditNonWorkingDaysForm: FC<EditNonWorkingDaysFormProps> = (props) => {
  const {
    employee,
    nonWorkingDays,
    isLoading,
    onSubmit,
    methods,
    closeModal,
    variant = 'modal',
    isEditMode = variant === 'standard' ? true : false,
    isContentOpen = true,
  } = props;

  const { watch } = methods;
  const currentValue = watch('value');

  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    setHasChanged(currentValue != nonWorkingDays.vacationDaysCurrentYear);
  }, [currentValue]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} name='formNonWorkingDays'>
        <div className={`edit-nwd-form--${variant}`}>
          <h1>{employee}</h1>
          {isContentOpen && (
            <ul>
              <li>
                <FieldWrapper<InputProps>
                  name='value'
                  fieldComponent={Input}
                  label='Non working days'
                  type='number'
                  min={0}
                  max={40}
                  disabled={variant === 'standard' && !isEditMode}
                />
              </li>

              <li>
                <p>Used days</p>
                <b>{nonWorkingDays.usedVacationDays}</b>
              </li>
              <li>
                <p>Remaining days</p>
                <b>{nonWorkingDays.totalVacationDaysLeft}</b>
                <div className={`edit-nwd-form--${variant}__remaining`}>
                  <div className={`edit-nwd-form--${variant}__remaining__year`}>
                    Current year:
                    <div className={`edit-nwd-form--${variant}__remaining__days`}>
                      {nonWorkingDays.vacationDaysLeftCurrentYear}
                    </div>
                  </div>
                  <div className={`edit-nwd-form--${variant}__remaining__year`}>
                    Previous year:
                    <div className={`edit-nwd-form--${variant}__remaining__days`}>
                      {nonWorkingDays.vacationDaysLeftPreviousYear}
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          )}

          {isContentOpen && (
            <div className={`edit-nwd-form--${variant}__progress-circle`}>
              <ProgressCircle
                totalDays={nonWorkingDays.vacationDaysCurrentYear}
                daysLeft={nonWorkingDays.totalVacationDaysLeft}
              />
            </div>
          )}

          {isContentOpen && (
            <div className={`edit-nwd-form--${variant}__actions-wrapper`}>
              {(variant === 'modal' || isEditMode) && (
                <Button
                  type='button'
                  onClick={() => {
                    methods.reset({
                      value: nonWorkingDays.vacationDaysCurrentYear,
                    });
                    closeModal();
                  }}
                  variant='outline'
                  className={`edit-nwd-form--${variant}__actions-wrapper__cancel`}
                >
                  Cancel
                </Button>
              )}
              {(variant === 'modal' || isEditMode) && (
                <Button
                  type='submit'
                  loading={isLoading}
                  disabled={!hasChanged}
                  className={`edit-nwd-form--${variant}__actions-wrapper__save`}
                >
                  Save
                </Button>
              )}
            </div>
          )}
        </div>
      </form>
    </FormProvider>
  );
};

export default EditNonWorkingDaysForm;

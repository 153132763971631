import { yupResolver } from '@hookform/resolvers/yup';
import { Avatar, Button, FieldWrapper } from 'components';
import Input, { InputProps } from 'components/Input/Input.component';
import { TMultiplierTableRow } from 'pages/SettingsPage/types/TMultiplierTableRow';
import React, { useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { MSG_REQUIRED_FIELD } from 'validation';
import * as Yup from 'yup';
import './MultiplierForm.styles.scss';
import { useNavigate } from 'react-router-dom';

export type MultiplierFormValues = { name: string; value: number };

type MultiplierFormProps = {
  onSubmit: (values: MultiplierFormValues) => void;
  title: string;
  buttonText: string;
  multiplier?: TMultiplierTableRow;
};

const MultiplierForm: React.FC<MultiplierFormProps> = ({
  onSubmit,
  title,
  buttonText,
  multiplier,
}) => {
  const validation = Yup.object().shape({
    name: Yup.string().required(''),
    value: Yup.number().required(MSG_REQUIRED_FIELD),
  });

  const methods = useForm<MultiplierFormValues>({
    mode: 'onTouched',
    resolver: yupResolver(validation),
    defaultValues: multiplier
      ? { value: multiplier.value, name: multiplier.name }
      : {
          value: 1,
        },
  });

  const submitDocument = useCallback(
    async (v: MultiplierFormValues) => {
      return onSubmit(v);
    },
    [document, methods],
  );

  const navigate = useNavigate();

  const renderMultipliers = useMemo(() => {
    if (!multiplier) return <></>;
    return (
      <>
        <h4>Employees under multiplier ({multiplier.users.length})</h4>
        <ul
          className={`multiplier-users ${multiplier.users.length > 10 && 'multiplier-users-scrollable'}`}
        >
          {multiplier
            ? multiplier.users.map((user) => (
                <li
                  key={user.id}
                  onClick={() => navigate(`/employees/manage-employees/${user.id}`)}
                >
                  <Avatar imageUrl={user.profileImageUrl} alt={'employee-image'} size='sm' />
                  <div>
                    <p>{user.firstName}</p>
                    <p>{user.lastName}</p>
                  </div>
                </li>
              ))
            : undefined}
        </ul>
      </>
    );
  }, []);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(submitDocument)} name='formVacation'>
        <h1>{title}</h1>
        <FieldWrapper<InputProps>
          name='name'
          fieldComponent={Input}
          label='Multiplier name'
          type='text'
          required
          asterix
        />
        <FieldWrapper<InputProps>
          name='value'
          fieldComponent={Input}
          label='Value'
          type='number'
          step='.01'
          required
          min={1}
          asterix
          helperText='* Changing the value will automatically be updated for employees that are under this multiplier.'
        />

        {renderMultipliers}

        <Button
          disabled={!methods.formState.isDirty}
          loading={methods.formState.isSubmitting}
          type='submit'
        >
          {buttonText}
        </Button>
      </form>
    </FormProvider>
  );
};

export default MultiplierForm;

import { yupResolver } from '@hookform/resolvers/yup';
import React, { FC, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { AuthContainer, Button, FieldWrapper, Input, Link, PasswordField } from 'components';
import { InputProps } from 'components/Input/Input.component';
import { APP_URI } from 'config';
import { API_PATH } from 'constant';
import { useAuth } from 'context';
import { RequsetMethod } from 'enums';
import { useAxios } from 'hooks';
import { ErrorType, LinkVariant } from 'models/types';
import { User } from 'models/User';
import { snackbar } from 'modules';
import { EMAIL_REGEX, MSG_EMAIL_INVALID, MSG_REQUIRED } from 'validation';

import { AxiosError } from 'axios';

import './LoginPage.styles.scss';

const validation = Yup.object().shape({
  email: Yup.string()
    .email(MSG_EMAIL_INVALID)
    .required(MSG_REQUIRED)
    .matches(EMAIL_REGEX, MSG_EMAIL_INVALID),
  password: Yup.string().required(MSG_REQUIRED),
});

interface LoginUser {
  email: string;
  password: string;
}

type IResponse = {
  accessToken: string;
  user: User | null;
};
type IError = {
  message: string;
  error: string;
  statusCode: number;
};

const LoginPage: FC = () => {
  const navigate = useNavigate();

  const { setToken, setUser } = useAuth();

  const methods = useForm<LoginUser>({
    resolver: yupResolver(validation),
    mode: 'onTouched',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const handleError = (error: AxiosError<ErrorType, any>) => {
    error.message = error.response?.data.message || error.message;
    snackbar.show({ message: error.message, type: 'error' });
  };

  const { loading, request: login } = useAxios<IResponse, IError>({
    url: API_PATH.LOGIN,
    method: RequsetMethod.POST,
    onResponse: (responseParam) => {
      setToken(responseParam?.data.accessToken || '');
      setUser(responseParam?.data.user);
      navigate(`${APP_URI.base}`);
    },
    onError: handleError,
  });

  const handleSubmit = useCallback(
    async (data: LoginUser) => {
      await login({ payload: data });
    },
    [login],
  );

  return (
    <AuthContainer title='Login'>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)} className='login'>
          <FieldWrapper<InputProps>
            fieldComponent={Input}
            name='email'
            label='Email'
            type='email'
            variant='filled'
            className='login__email-field'
          />
          <FieldWrapper<InputProps>
            fieldComponent={PasswordField}
            name='password'
            label='Password'
            type='password'
            variant='filled'
          />
          <div className='login__forgot-password'>
            <p className='login__forgot-password__text'>Forgot your password?</p>
            <Link text='Reset password' to={APP_URI.passwordRecovery} variant={LinkVariant.Text} />
          </div>
          <Button
            type='submit'
            className='login__button'
            disabled={!methods.formState.isValid}
            loading={loading}
          >
            Log in
          </Button>
        </form>
      </FormProvider>
    </AuthContainer>
  );
};

export default LoginPage;

import React, { InputHTMLAttributes } from 'react';

import './SelectV2.styles.scss';
import classNames from 'classnames';

type DefaultSelectProps = InputHTMLAttributes<HTMLSelectElement>;

export type SelectV2Props = {
  options: { value: string; label: string | number }[];
  value: string;
  label?: string;
  className?: string;
  hasError?: boolean;
  errorMessage?: string;
  onChange: (value: string) => void;
  asterix?: boolean;
} & DefaultSelectProps;

const SelectV2: React.FC<SelectV2Props> = ({
  options,
  label,
  className,
  hasError,
  errorMessage,
  value,
  onChange,
  placeholder,
  asterix,
  disabled,
  ...rest
}) => {
  const classes = classNames('ne-select', className);

  const handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(event.target.value);
  };

  return (
    <div className={classes}>
      {label && (
        <label>
          {label}
          {asterix && '*'}
        </label>
      )}

      <select onChange={handleOnChange} {...rest} disabled={disabled} key={value} value={value}>
        {placeholder ? <option value={''}>{placeholder}</option> : undefined}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>

      <small className={classNames('ncoded-small', hasError && 'ncoded-small--invalid-input')}>
        {errorMessage}
      </small>
    </div>
  );
};

export default SelectV2;

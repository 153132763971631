import React from 'react';

import { PageLayout } from 'components/layout/PageLayout';
import { PageHeader } from 'components/layout/PageHeader';
import { SectionBlock } from 'components/layout/SectionBlock';
import Table from 'components/Table';
import TablePagination from 'components/TablePagination';
import { employeeDocumentTableColumns } from './utils';
import usePaginatedData from 'hooks/usePaginatedData';
import { EmployeeDocument } from 'models/EmployeeDocument';
import { YearPicker } from 'components';
import { getYear } from 'date-fns';
import { useAuth } from 'context';

import './DocumentsPage.styles.scss';

const DocumentsPage = () => {
  const {
    data: userDocuments,
    pagination,
    params,
    loading: loadingUserFiles,
    changePageNumber,
    onYearChange,
  } = usePaginatedData<EmployeeDocument>(
    {
      sort: { startDate: -1, createdAt: -1 },
      limit: 10,
      page: 1,
    },
    '/files',
  );

  const { user } = useAuth();

  return (
    <PageLayout className='documents-page'>
      <PageHeader title='My documents' />
      <SectionBlock
        title='Employment information'
        loading={loadingUserFiles}
        asideFromTitle={
          user?.role === 'ADMIN' ? (
            <YearPicker
              year={params?.filter?.year || getYear(new Date())}
              onYearChange={onYearChange}
              maxYear={2030}
              minYear={2000}
            />
          ) : null
        }
      >
        <div className='documents-page__table-container'>
          <Table
            data={userDocuments}
            prepareData={employeeDocumentTableColumns}
            noDataMessage="Welcome to Ncoded! 👋 Here you'll find your employment related documents once the admin adds them."
          />
        </div>

        {pagination && (
          <TablePagination
            totalPages={pagination?.totalPages}
            currentPage={pagination?.currentPage}
            totalItems={pagination?.totalItems}
            pageSize={pagination?.pageSize}
            onPageChange={changePageNumber}
          />
        )}
      </SectionBlock>
    </PageLayout>
  );
};
export default DocumentsPage;

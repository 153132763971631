import { ChevronIcon, PenIcon } from 'assets';
import EmployeeContext from 'pages/EmployeeProfilePage/context/EmploeeContext';
import React, { useCallback, useContext, useState } from 'react';
import useEmployeeVacationsStore from 'pages/NonWorkingDaysPage/stores/useEmployeeVacationsStore';
import { snackbar } from 'modules';
import { EditNonWorkingDaysForm } from 'components/EditNonWorkingDaysModal/components';
import { useForm } from 'react-hook-form';
import { EditNonWorkingDaysValue } from 'components/EditNonWorkingDaysModal/EditNonWorkingDaysModal.component';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { MSG_REQUIRED_FIELD } from 'validation';
import { Button } from 'components';

const validation = () =>
  Yup.object().shape({
    value: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required(MSG_REQUIRED_FIELD)
      .moreThan(-1),
  });

const EmployeeNonWorkingDays = () => {
  const { employee, refreshEmployeeData } = useContext(EmployeeContext);
  const { updateNonWorkingDays } = useEmployeeVacationsStore();
  const [isEditMode, setIsEditMode] = useState(false);
  const [isContentOpen, setIsContentOpen] = useState(false);

  const onEditSuccess = useCallback(() => {
    snackbar.show({
      message: `You have successfully changed non working days for ${employee.firstName} ${employee.lastName}.`,
      type: 'success',
    });
    refreshEmployeeData();
  }, []);

  const onEditError = useCallback((errorMessage: string) => {
    const finalMessage = errorMessage.replace('vacationDaysCurrentYear', 'No of days');
    snackbar.show({ message: finalMessage, type: 'error' });
  }, []);

  const handleEdit = useCallback(
    (value: number) => {
      return updateNonWorkingDays(employee.id, value, onEditSuccess, onEditError);
    },
    [onEditError, onEditSuccess, employee],
  );

  const methods = useForm<EditNonWorkingDaysValue>({
    mode: 'onTouched',
    resolver: yupResolver(validation()),
    values: {
      value: employee.vacationDaysCurrentYear as number,
    },
  });

  const handleSubmit = useCallback(
    (values: EditNonWorkingDaysValue) => {
      return handleEdit(values.value);
    },
    [handleEdit],
  );

  return (
    <EditNonWorkingDaysForm
      employee={
        <div>
          <p
            className={`employee-non-working-days__${isContentOpen ? 'open' : 'closed'}`}
            onClick={() => setIsContentOpen(!isContentOpen)}
          >
            <Button variant='icon' type='button' onClick={() => setIsContentOpen(!isContentOpen)}>
              <ChevronIcon />
            </Button>
            Non working days
          </p>
          {isContentOpen && (
            <div>
              <Button
                variant='icon'
                type='button'
                onClick={() => setIsEditMode(true)}
                disabled={isEditMode}
              >
                <PenIcon />
              </Button>
            </div>
          )}
        </div>
      }
      nonWorkingDays={{
        vacationDaysCurrentYear: employee.vacationDaysCurrentYear as number,
        usedVacationDays: employee.usedVacationDays as number,
        totalVacationDaysLeft: employee.totalVacationDaysLeft as number,
        vacationDaysLeftPreviousYear: employee.vacationDaysLeftPreviousYear as number,
        vacationDaysLeftCurrentYear: employee.vacationDaysCurrentYear as number,
      }}
      onSubmit={handleSubmit}
      isLoading={methods.formState.isSubmitting}
      methods={methods}
      closeModal={() => setIsEditMode(false)}
      variant='standard'
      isEditMode={isEditMode}
      isContentOpen={isContentOpen}
    />
  );
};

export default EmployeeNonWorkingDays;

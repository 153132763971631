import { Modal } from 'components/Modal';
import React, { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { MSG_REQUIRED_FIELD } from 'validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAxios } from 'hooks';
import { snackbar } from 'modules';
import DateService from 'services/Date.service';
import './EmployeeRequestVacation.styles.scss';
import { EmployeeRequestVacationForm } from './components/EmployeeRequestVacationForm';

type RequestVacationModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
};

export type RequestVacationType = {
  description: string;
  startDate: Date | string;
  endDate: Date | string;
};

type ErrorResponseType = {
  message: string;
};

const validation = () =>
  Yup.object().shape({
    description: Yup.string()
      .required(MSG_REQUIRED_FIELD)
      .max(600, 'Description must be at most 600 characters'),
    startDate: Yup.mixed<Date | string>()
      .required(MSG_REQUIRED_FIELD)
      .test('isStartDateValid', 'Start date cannot be today', (value) => {
        const selectedDate = new Date(value);
        const currentDate = new Date();

        return selectedDate > currentDate;
      })
      .test('isNotWeekend', 'Weekend days cannot be selected', (value) => {
        const selectedDate = new Date(value);
        const dayOfWeek = selectedDate.getDay(); // 0 for Sunday, 6 for Saturday

        return dayOfWeek !== 0 && dayOfWeek !== 6; // Return true if not Sunday or Saturday
      }),
    endDate: Yup.mixed<Date | string>()
      .required(MSG_REQUIRED_FIELD)
      .test('isEndDateValid', 'End date must be after or equal to start date', function (value) {
        const startDateValue = this.parent.startDate;

        if (!startDateValue) return true;

        const startDateObject = new Date(startDateValue);
        const endDateObject = new Date(value);

        return endDateObject >= startDateObject;
      })
      .test('isNotWeekend', 'Weekend days cannot be selected', (value) => {
        const selectedDate = new Date(value);
        const dayOfWeek = selectedDate.getDay(); // 0 for Sunday, 6 for Saturday
        return dayOfWeek !== 0 && dayOfWeek !== 6; // Return true if not Sunday or Saturday
      }),
  });

const EmployeeRequestVacation = (props: RequestVacationModalProps) => {
  const { isOpen, onRequestClose } = props;

  const methods = useForm<RequestVacationType>({
    mode: 'onChange',
    resolver: yupResolver(validation()),
  });

  const { request: addRequestVacation, loading: requestVacationLoading } = useAxios<
    RequestVacationType,
    ErrorResponseType
  >({
    url: `vacations`,
    method: 'POST',
    onResponse: () => {
      closeModal();
      snackbar.show({ message: 'You have successfully sent vacation request.', type: 'success' });
    },
    onError: (error) => {
      snackbar.show({ message: error.response?.data.message || error.message, type: 'error' });
    },
  });

  const handleSubmit = useCallback(
    (ev: RequestVacationType) => {
      addRequestVacation({
        payload: {
          startDate: DateService.formatDate(ev.startDate, 'yyyy-MM-dd'),
          endDate: DateService.formatDate(ev.endDate, 'yyyy-MM-dd'),
          description: ev.description,
        },
      });
    },
    [addRequestVacation],
  );

  const closeModal = useCallback(() => {
    onRequestClose();
    methods.reset();
  }, [onRequestClose, methods.reset]);

  const startDate = methods.watch('startDate');
  const endDate = methods.watch('endDate');

  useEffect(() => {
    if (startDate && endDate && new Date(startDate) > new Date(endDate)) {
      methods.setError('endDate', {
        type: 'manual',
        message: 'End date cannot be before start date.',
      });
    } else {
      methods.clearErrors('endDate');
    }
  }, [startDate, endDate, methods]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      hasCloseIcon
      className='employee-request-vacation-modal'
    >
      <div className='employee-request-vacation-modal__container'>
        <FormProvider {...methods}>
          <h1 className='employee-request-vacation-modal__container__title'>Vacation request</h1>
          <p className='employee-request-vacation-modal__container__notiffication'>
            Before requesting vacation please make sure that you have consulted with your management
            team and teammates, esspecially if you plan to take a longer break from work 🌴
          </p>
          <EmployeeRequestVacationForm
            onSubmit={handleSubmit}
            methods={methods}
            submitLoading={requestVacationLoading}
          />
        </FormProvider>
      </div>
    </Modal>
  );
};

export default EmployeeRequestVacation;

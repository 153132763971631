import { TableColumnItem } from 'components/Table/Table.component';
import { EmployeeNonWorkingDays } from 'models/NonWorkingDays';
import DateService from 'services/Date.service';
import React from 'react';
import EditWorkingDays from '../components/EditWorkingDays';
import { Button } from 'components';
import { SortIcon, UnsortedIcon } from 'assets';

const useWorkingDaysTableColumns = (
  handleSortByName: () => void,
  sortByName: number,
): TableColumnItem<EmployeeNonWorkingDays>[] => [
  {
    columnLabel: (
      <p className={`employee-sort-icon-${sortByName}`}>
        Employee
        <Button variant='icon' onClick={() => handleSortByName()}>
          <SortIcon />
        </Button>
      </p>
    ),
    columnName: 'employee',
    prepareCell: (data) => data.employee,
  },
  {
    columnLabel: 'Start date',
    columnName: 'start-date',
    prepareCell: (data) => (data.startDate ? DateService.formatDate(data.startDate) : '-'),
  },
  {
    columnLabel: 'End',
    prepareCell: (data) =>
      data.endDate ? DateService.formatDate(data.endDate) : !data.startDate ? '-' : 'N/A',
  },
  {
    columnLabel: 'No of days',
    prepareCell: (data) => data.nonWorkingDays.vacationDaysCurrentYear,
  },

  {
    columnLabel: 'Used days',
    columnName: 'used-days',
    prepareCell: (data) => data.nonWorkingDays.usedVacationDays,
  },
  {
    columnLabel: 'Remaining days',
    columnName: 'remaining-days',
    prepareCell: (data) => data.nonWorkingDays.totalVacationDaysLeft,
  },
  {
    columnLabel: 'Edit',
    columnName: 'edit',
    prepareCell: (data) => {
      return data?.nonWorkingDays.vacationDaysCurrentYear >= 0 ? (
        <EditWorkingDays data={data} />
      ) : null;
    },
  },
];

export default useWorkingDaysTableColumns;

import { useAxios, useModalState } from 'hooks';
import useDebounce from 'hooks/useDebounce';
import { Pagination, PaginationType } from 'models/types';
import { VacationRequestData } from 'models/VacationRequest';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Table from 'components/Table';
import TablePagination from 'components/TablePagination';
import { SectionBlock } from 'components/layout/SectionBlock';
import TabsV2 from 'components/TabsV2/TabsV2.component';
import { Input } from 'components';
import { SearchIcon } from 'assets';
import { generateUrl } from 'utils/generateUrl';
import { snackbar } from 'modules';
import { VacationRequestModal } from '../VacationRequestModal';
import { useTimeOffTableColumns } from 'pages/TimeOffsPage/hooks/useTimeOffTableColumns';
import useStatusFilterItems from 'pages/TimeOffsPage/hooks/useStatusFilterItems';

import './TimeOffsTable.styles.scss';
import EmployeeContext from 'pages/EmployeeProfilePage/context/EmploeeContext';

type ErrorType = {
  message: string;
  error: string;
  statusCode: number;
};

type TimeOffStatus = 'Submitted' | 'Approved' | 'Fulfilled' | 'Denied';

export type TimeOffsParams = {
  search: string;
  page: number;
  limit: number;
  filter: {
    status: TimeOffStatus;
  };
};

type TimeOffsTableProps = {
  isSearchVisible?: boolean;
  areTabsVisible?: boolean;
  initialStatus?: string;
};

const TimeOffsTable: React.FC<TimeOffsTableProps> = (props) => {
  const { isSearchVisible = true, areTabsVisible = true, initialStatus = '' } = props;
  const { employee } = useContext(EmployeeContext);
  const [selectedVacation, setSelectedVacation] = useState<VacationRequestData>();
  const [data, setData] = useState<VacationRequestData[]>([]);
  const [pagination, setPagination] = useState<Pagination>({} as Pagination);

  const getInitialParams = useCallback(
    () => ({
      limit: 10,
      page: 1,
      search: employee ? `${employee.firstName} ${employee.lastName}` : '',
      ...(initialStatus && { filter: { status: initialStatus as TimeOffStatus } }),
    }),
    [employee, initialStatus],
  );

  const [params, setParams] = useState<Partial<TimeOffsParams>>(getInitialParams);
  const [searchString, setSearchString] = useState(
    employee ? employee.firstName + ' ' + employee.lastName : '',
  );
  const [sortByRequestedOn, setSortByRequestedOn] = useState(1);
  const [sortByStartDate, setSortByStartDate] = useState(0);

  const {
    isOpen: isVacationRequestModalOpen,
    openModal: openVacationRequestModal,
    closeModal: closeVacationRequestModal,
  } = useModalState();

  const { request: getVacationRequests, loading: loadingVacations } = useAxios<
    PaginationType<VacationRequestData>,
    ErrorType
  >({
    url: generateUrl('/vacations', params),
    method: 'GET',
    params,
    onResponse: (response) => {
      const { data } = response;
      const { items, ...rest } = data;

      if (employee?.id) {
        setData(items.filter((item) => item.userId == employee?.id));
      } else {
        setData(items);
      }
      setPagination(rest as Pagination);
    },
  });

  const handleOnRowClick = useCallback(
    (data: VacationRequestData) => {
      setSelectedVacation(data);
      data?.status && openVacationRequestModal();
    },
    [openVacationRequestModal],
  );

  const handleSortByRequestedOn = () => {
    setSortByRequestedOn(-sortByRequestedOn);
    setSortByStartDate(0);
  };

  useEffect(() => {
    if (sortByRequestedOn && sortByRequestedOn !== 0) {
      setParams((prev) => ({
        ...prev,
        page: 1,
        sort: { createdAt: sortByRequestedOn },
      }));
    }
  }, [sortByRequestedOn]);

  const handleSortByStartDate = () => {
    setSortByStartDate((currentSort) => {
      return currentSort === 0 ? 1 : currentSort === 1 ? -1 : 0;
    });
  };

  useEffect(() => {
    if (sortByStartDate && sortByStartDate !== 0) {
      setParams((prev) => ({
        ...prev,
        page: 1,
        sort: { startDate: sortByStartDate },
      }));
    } else {
      setParams((prev) => ({
        ...prev,
        page: 1,
        sort: { createdAt: sortByRequestedOn },
      }));
    }
  }, [sortByStartDate]);

  const updateTimeOffsData = () => {
    setData(
      data.filter((row) => {
        return row._id != selectedVacation?._id;
      }),
    );
  };

  const { request: rejectRequest } = useAxios({
    url: `vacations/${selectedVacation?._id}/reject`,
    method: 'POST',
    overlayClass: 'submitted-request-modal',
    onResponse: () => {
      updateTimeOffsTabs();
      updateTimeOffsData();
      closeVacationRequestModal();
      snackbar.show({
        message: 'You have successfully rejected vacation request.',
        type: 'success',
      });
    },
    onError: (error) => {
      snackbar.show({ message: error.message, type: 'error' });
    },
  });

  const { request: approveRequest } = useAxios({
    url: `vacations/${selectedVacation?._id}/approve`,
    method: 'POST',
    overlayClass: 'submitted-request-modal',
    onResponse: () => {
      updateTimeOffsTabs();
      updateTimeOffsData();
      closeVacationRequestModal();
      snackbar.show({
        message:
          'You have approved the request. The administrator will receive a notification to prepare written approval.',
        type: 'success',
      });
    },
    onError: (error) => {
      snackbar.show({ message: error.message, type: 'error' });
    },
  });

  const columns = useTimeOffTableColumns(
    handleOnRowClick,
    handleSortByStartDate,
    handleSortByRequestedOn,
    sortByStartDate,
    sortByRequestedOn,
  );

  const { statusFilterItems, updateTimeOffsTabs } = useStatusFilterItems(params);

  const onSearchStringChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (isSearchVisible) setSearchString(e.target.value);
  }, []);

  const debouncedSearchString = useDebounce(searchString, 500);

  const changePageNumber = useCallback((pageNumber: number) => {
    setParams((prev) => ({ ...prev, page: pageNumber }));
  }, []);

  const onStatusChange = useCallback(
    (id: string) => {
      changePageNumber(1);
      setPagination((prev) => ({ ...prev, currentPage: 1 }));
      if (id === 'All') {
        setParams((prev) => {
          delete prev['filter'];
          return { ...prev };
        });
      } else {
        setParams((prev) => ({
          ...prev,
          filter: { status: id as TimeOffStatus },
        }));
      }
    },
    [pagination],
  );

  useEffect(() => {
    setParams((prev) => ({
      ...prev,
      search: debouncedSearchString,
      page: 1,
    }));
  }, [debouncedSearchString]);

  useEffect(() => {
    updateTimeOffsTabs();
    getVacationRequests({});
  }, [params]);

  const noTableDataMessage = useMemo(() => {
    return `There are no ${(params.filter?.status as string) ? params.filter?.status?.toLowerCase() : ''} ${
      params.search
        ? 'requests for time off from the employee under that name.'
        : 'requests for vacation.'
    }`;
  }, [params]);

  return (
    <div className='ne-timeoffs-table'>
      {selectedVacation && (
        <VacationRequestModal
          isOpen={isVacationRequestModalOpen}
          onClose={() => {
            setSelectedVacation(undefined);
            closeVacationRequestModal();
          }}
          request={selectedVacation}
          onSuccess={() => {
            updateTimeOffsTabs();
            updateTimeOffsData();
          }}
          status={selectedVacation.status}
          title={selectedVacation.status + ' vacation request'}
          approveVacationRequest={() => approveRequest({})}
          rejectVacationRequest={() => rejectRequest({})}
        />
      )}

      <SectionBlock loading={loadingVacations} className='ne-timeoffs-table__data'>
        <div className='ne-timeoffs-table__filters'>
          {areTabsVisible && (
            <TabsV2
              items={statusFilterItems}
              onFilterSelect={onStatusChange}
              selectedItem={params.filter?.status as string}
            />
          )}
          {isSearchVisible && (
            <Input
              prefixNode={<SearchIcon />}
              value={searchString}
              onChange={onSearchStringChange}
              placeholder='Search for an employee...'
            />
          )}
        </div>

        <div className='ne-timeoffs-table__table-container'>
          <Table
            data={data}
            prepareData={columns}
            noDataMessage={noTableDataMessage}
            // onTableRowClick={onRowClick}
          />
        </div>

        <TablePagination
          totalPages={pagination?.totalPages}
          currentPage={pagination?.currentPage}
          onPageChange={changePageNumber}
          totalItems={pagination?.totalItems}
          pageSize={pagination?.pageSize}
        />
      </SectionBlock>
    </div>
  );
};

export default TimeOffsTable;

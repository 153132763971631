import classNames from 'classnames';
import React, { useState } from 'react';

import './Checkbox.styles.scss';

type DefaultInputProps = React.InputHTMLAttributes<HTMLInputElement>;

export type CheckboxProps = {
  label?: string;
  hasError?: boolean;
  helperText?: string;
  useTimeout?: boolean;
  onChange: (checked: boolean) => void;
} & Omit<DefaultInputProps, 'type'>;

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  className,
  id,
  checked,
  useTimeout = false,
  onChange,
  ...rest
}: CheckboxProps) => {
  const classes = classNames('checkbox', className);

  const [isCheckedTemporarily, setIsCheckedTemporarily] = useState<boolean>(false);

  const handleClick = () => {
    if (!useTimeout) {
      onChange(!checked);
    } else {
      setIsCheckedTemporarily(true);
      setTimeout(() => {
        onChange(!checked);
        setIsCheckedTemporarily(false);
      }, 200);
    }
  };

  return (
    <label htmlFor={id || rest.name} className={classes}>
      <input
        id={id || rest.name}
        type='checkbox'
        className='checkbox__input'
        hidden
        {...rest}
        checked={isCheckedTemporarily || checked} // Use the temporary checked state for visual indication
        onChange={handleClick}
      />
      <span className='checkbox__box' />
      {label}
    </label>
  );
};
export default Checkbox;

import { TableColumnItem } from 'components/Table/Table.component';
import DateService from 'services/Date.service';
import { formatNumber } from 'utils/formatNumber';
import { EmployeeDocument } from 'models/EmployeeDocument';
import React from 'react';
import EmployeeDocumentActions from 'pages/EmployeeProfilePage/components/EmploymentInformation/components/EmployeeDocumentActions';

const useEmploymentInfoTableColumns = (): TableColumnItem<EmployeeDocument>[] => [
  {
    columnLabel: 'Type',
    prepareCell: (data) => data.type || '',
  },
  {
    columnLabel: 'Start',
    prepareCell: (data) => DateService.formatDate(data.startDate),
  },
  {
    columnLabel: 'End',
    prepareCell: (data) => (data.endDate ? DateService.formatDate(data.endDate) : ''),
  },
  {
    columnLabel: 'Net salary',
    prepareCell: (data) =>
      `${formatNumber(data.netSalary?.amount || 0, 2)} ${data.netSalary?.currency || ''}`,
  },
  {
    columnLabel: 'Gross salary',
    prepareCell: (data) =>
      `${formatNumber(data.grossSalary.amount, 2)} ${data.grossSalary.currency}`,
  },

  {
    columnLabel: 'Actions',
    columnName: 'actions',
    prepareCell: (data) => {
      return <EmployeeDocumentActions document={data} />;
    },
  },
  {
    columnLabel: 'Note',
    columnName: 'note',
    prepareCell: (data) => data.note || '',
  },
];

export default useEmploymentInfoTableColumns;

import React, { FC, ReactElement, useCallback, useEffect, useState } from 'react';

import { API_PATH } from 'constant';
import { RequsetMethod } from 'enums';
import { useAxios } from 'hooks';
import { User } from 'models/User';
import { AuthContext } from './AuthContext.context';
import useGlobalMiltipliersStore from 'store/useGlobalMiltipliersStore';

type AuthProps = {
  children: ReactElement;
};

const AuthProvider: FC<AuthProps> = ({ children }) => {
  const [token, setTokenState] = useState<string | null>(localStorage.getItem('token'));
  const [user, setUser] = useState<User | null>(null);

  const { getMultipliers } = useGlobalMiltipliersStore();

  const { setError, request: fetchCurrentUser } = useAxios<User, Error>({
    url: API_PATH.CURRENT_USER,
    method: RequsetMethod.GET,
    onError: (errorParam) => {
      setError(errorParam);
      setTokenState(null);
    },
    onResponse: async (res) => {
      setUser(res.data);
      getMultipliers();
    },
  });

  const setToken = useCallback((token: string | null) => {
    setTokenState(token);

    if (token) {
      localStorage.setItem('token', token);
    } else {
      localStorage.removeItem('token');
      setUser(null);
    }
  }, []);
  const triggerRefetch = async () => {
    await fetchCurrentUser({});
  };

  useEffect(() => {
    if (token) fetchCurrentUser({});
  }, [token]);

  return (
    <AuthContext.Provider value={{ token, setToken, user, setUser, triggerRefetch }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

import React, { FC, useCallback, useEffect, useState } from 'react';
import './NonWorkingDaysTable.styles.scss';
import { SectionBlock } from 'components/layout/SectionBlock';
import TablePagination from 'components/TablePagination';
import Table from 'components/Table';
import { getYear } from 'date-fns';
import useWorkingDaysTableColumns from './hooks/useWorkingDaysTableColumns';
import useEmployeeVacationsStore from 'pages/NonWorkingDaysPage/stores/useEmployeeVacationsStore';
import useDebounce from 'hooks/useDebounce';
import { SearchIcon } from 'assets';
import { Input } from 'components';
import { SortingOrder } from 'models/types';

const NonWorkingDaysTable: FC<any> = () => {
  const [searchString, setSearchString] = useState('');
  const { vacations, getVacations, isLoading, pagination, params, applyAndSearch } =
    useEmployeeVacationsStore();

  const [sortByName, setSortByName] = useState(SortingOrder.ASCENDING);

  const handleSortByName = () => {
    setSortByName(-sortByName);
  };

  useEffect(() => {
    if (sortByName) getVacations({ sort: { firstName: sortByName, lastName: sortByName } });
  }, [sortByName]);

  const onPageChange = useCallback((page: number) => {
    getVacations({ page });
  }, []);

  useEffect(() => {
    getVacations();
  }, []);

  const columns = useWorkingDaysTableColumns(handleSortByName, sortByName);

  const debouncedSearchString = useDebounce(searchString, 500);

  useEffect(() => {
    applyAndSearch({ search: debouncedSearchString });
  }, [debouncedSearchString]);

  const onSearchStringChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
  }, []);

  return (
    <SectionBlock
      title={`List of all employees with their non working days for ${getYear(new Date())}`}
      className='ne-non-working-days'
      loading={isLoading}
    >
      <Input
        prefixNode={<SearchIcon />}
        value={searchString}
        onChange={onSearchStringChange}
        placeholder='Search for an employee...'
        className='search-input'
      />
      <div className='ne-non-working-days__table-container'>
        <Table
          data={vacations}
          prepareData={columns}
          noDataMessage={
            vacations.length === 0 && debouncedSearchString !== '' ? '' : 'There are no employees.'
          }
        />
      </div>

      {pagination?.totalItems > 0 && (
        <TablePagination
          totalPages={pagination?.totalPages}
          currentPage={pagination?.currentPage}
          onPageChange={onPageChange}
          totalItems={pagination?.totalItems}
          pageSize={params.limit}
        />
      )}
    </SectionBlock>
  );
};

export default NonWorkingDaysTable;

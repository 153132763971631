import { Button } from 'components/Button';
import { APP_URI } from 'config';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PageLayout } from 'components/layout/PageLayout';
import { PageHeader } from 'components/layout/PageHeader';
import { StatusIcon } from 'assets';
import TimeOffsTable from './components/TimeOffsTable/TimeOffsTable.component';

import './TimeOffsPage.styles.scss';

type TimeOffStatus = 'Submitted' | 'Approved' | 'Fulfilled' | 'Denied';
export type TimeOffsParams = {
  search: string;
  page: number;
  limit: number;
  filter: { status: TimeOffStatus };
};

const TimeOffsPage = () => {
  const navigate = useNavigate();

  return (
    <PageLayout className='ne-timeoffs'>
      <PageHeader
        title='Time offs'
        className='ne-timeoffs__page-header'
        asideFromTitle={
          <Button
            onClick={() => navigate(APP_URI.nonworkingdays)}
            className='ne-timeoffs__status-button'
          >
            <div className='ne-timeoffs__icon'>
              <StatusIcon />
            </div>
            Status of non working days
          </Button>
        }
      ></PageHeader>
      <TimeOffsTable />
    </PageLayout>
  );
};

export default TimeOffsPage;

import { Tabs } from 'components';
import { useAuth } from 'context';
import PersonalInformation from './components/PersonalInformation';
import ChangePassword from './components/ChangePassword';
import React from 'react';
import { BookReader, Password } from 'assets';

import './Profile.styles.scss';

const tabsData = [
  {
    label: (
      <div className='profile__tabs__personal-info'>
        <BookReader /> Personal information
      </div>
    ),
    content: <PersonalInformation />,
  },
  {
    label: (
      <div className='profile__tabs__change-pass'>
        <Password /> Change password
      </div>
    ),
    content: <ChangePassword />,
  },
];

const ProfilePage = () => {
  const { user } = useAuth();

  if (!user) return <></>;

  return (
    <div className='profile'>
      <Tabs className='profile__tabs' tabs={tabsData} />
    </div>
  );
};

export default ProfilePage;

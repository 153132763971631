import Checkbox, { CheckboxProps } from 'components/Checkbox/Checkbox.component';
import { DocumentDropzone } from 'components/DocumentDropzone';
import { DocumentDropzoneProps } from 'components/DocumentDropzone/DocumentDropzone.component';
import { FieldWrapper } from 'components/FieldWrapper';
import Input, { InputProps } from 'components/Input/Input.component';
import Textarea, { TextareaProps } from 'components/Textarea/Textarea.components';
import { Multiplier } from 'models/Multiplier';
import React, { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { formatNumber } from 'utils/formatNumber';
import { currencyOptions, employeeContractOptions } from './utils';
import { DocumentFormValues } from 'pages/EmployeeProfilePage/components/EmploymentInformation/types/DocumentFormValues';
import SelectV2, { SelectV2Props } from 'components/SelectV2/SelectV2.component';
import { DatePicker } from 'components';

import './DocumentForm.styles.scss';

export type DocumentFormProps = {
  methods: UseFormReturn<DocumentFormValues>;
  multipliers: Multiplier[];
  multiplierDisabled?: boolean;
};

const DocumentForm: FC<DocumentFormProps> = (props) => {
  const { methods, multipliers, multiplierDisabled } = props;

  const grossSalary = () =>
    `${formatNumber(
      (methods.watch('netSalaryAmount') || 0) *
        (multipliers.find((multiplier) => multiplier.id === methods.watch('multiplierId'))?.value ||
          0),
      2,
    )} 
      ${methods.watch('currency')}`;

  return (
    <div className='document-form'>
      <div className='document-form__row'>
        <FieldWrapper<SelectV2Props>
          name='type'
          fieldComponent={SelectV2}
          options={employeeContractOptions}
          label='Document type'
          placeholder='Select type'
          asterix
        />
      </div>
      <div className='document-form__row document-form__dates'>
        <FieldWrapper<any>
          name='startDate'
          label='Start Date'
          fieldComponent={DatePicker}
          asterix
          minDate={new Date('November 1, 2019 00:00:00')}
        />
        <div className='document-form__end-date'>
          <FieldWrapper<any>
            name='endDate'
            label='End Date'
            fieldComponent={DatePicker}
            disabled={methods.watch('noEndDate')}
            asterix
            minDate={new Date('November 1, 2019 00:00:00')}
          />
          <FieldWrapper<CheckboxProps>
            name='noEndDate'
            key={`end-date-${methods.formState.defaultValues?.noEndDate}`}
            label='No end date'
            fieldComponent={Checkbox}
            className='document-form__no-end-date'
            defaultChecked={methods.formState.defaultValues?.noEndDate}
            checked={methods.formState.dirtyFields.noEndDate ? true : false}
          />
        </div>
      </div>
      <div className='document-form__row document-form__salary'>
        <FieldWrapper<InputProps>
          name='netSalaryAmount'
          fieldComponent={Input}
          type='number'
          valueAsNumber
          label='Net salary'
          asterix
          min={0}
        />
        <FieldWrapper<SelectV2Props>
          name='currency'
          fieldComponent={SelectV2}
          options={currencyOptions}
          label='Currency'
          asterix
        />
        <FieldWrapper<SelectV2Props>
          name='multiplierId'
          label='Multiplier'
          fieldComponent={SelectV2}
          options={multipliers.map((multiplier) => {
            return { label: multiplier.name, value: multiplier.id };
          })}
          placeholder='Choose multiplier'
          disabled={multiplierDisabled}
          asterix
        />
      </div>
      <div className='document-form__row document-form__gross-salary'>
        <b>Gross salary</b>
        <p>{grossSalary()}</p>
      </div>
      <FieldWrapper<TextareaProps>
        name='note'
        label='Note'
        placeholder='Enter note...'
        fieldComponent={Textarea}
      />
      <div className='document-form__row document-form__doc'>
        <b>Document*</b>
        <FieldWrapper<DocumentDropzoneProps> name='file' fieldComponent={DocumentDropzone} />
      </div>
      {methods.formState.errors.root && (
        <small className='document-form__error-msg'>{methods.formState.errors.root?.message}</small>
      )}
    </div>
  );
};

export default DocumentForm;

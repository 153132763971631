import React, { ElementType } from 'react';

import { FieldValues, RegisterOptions, useController } from 'react-hook-form';
type FieldWrapperProps = {
  name: string;
  fieldComponent: ElementType;
  className?: string;
  helpertext?: string;
  validations?: Omit<
    RegisterOptions<FieldValues, string>,
    'valueAsDate' | 'setValueAs' | 'disabled'
  >;
  customOnBlur?: () => void;
  valueAsNumber?: boolean;
};

type FieldWrapperProp<T> = FieldWrapperProps & Partial<T>;

const FieldWrapper = <T,>({
  name,
  fieldComponent: Component,
  className,
  helpertext,
  validations: rules,
  customOnBlur,
  valueAsNumber,
  ...rest
}: FieldWrapperProp<T>) => {
  const {
    field: { ref, onBlur: onBlurDefault, onChange, ...restField },
    fieldState,
  } = useController({ name, rules });

  const errorMessage = fieldState.error?.message; // Validation error
  const onBlur = () => {
    customOnBlur?.();
    onBlurDefault();
  };

  return (
    <Component
      hasError={!!errorMessage}
      helpertext={helpertext}
      errorMessage={errorMessage}
      ref={ref}
      {...rest}
      {...restField}
      className={className}
      onBlur={onBlur}
      onChange={valueAsNumber ? (value: any) => onChange(value.target.valueAsNumber) : onChange}
    />
  );
};

export default FieldWrapper;

import Table from 'components/Table';
import { User } from 'models/User';
import React, { FC, useEffect, useState } from 'react';
import useEmployeesTableColumns from '../../hooks/useEmployeesTableColumns';
import { toggleSortingOrder } from 'utils/toggleSortingOrder';

import './EmployeesTable.styles.scss';
import { SortingOrder } from 'models/types';
import { useNavigate } from 'react-router-dom';

type EmployeesProps = {
  employees: User[];
  onSortChange: (prop: number) => void;
};

const EmployeesTable: FC<EmployeesProps> = ({ employees, onSortChange }) => {
  const [sortByName, setSortByName] = useState(SortingOrder.UNSORTED);
  const navigate = useNavigate();

  const handleSortByName = () => {
    setSortByName(toggleSortingOrder(sortByName));
  };

  const employeesTableColumns = useEmployeesTableColumns(sortByName, handleSortByName);

  useEffect(() => {
    onSortChange(sortByName);
  }, [sortByName]);

  return (
    <div className='employees__table-container'>
      <Table
        data={employees}
        prepareData={employeesTableColumns}
        noDataMessage="Here, you'll find a list of your employees."
        isRowClickable={true}
      />
    </div>
  );
};

export default EmployeesTable;

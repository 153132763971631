// TabsHeader component

import React, { useState } from 'react';
import classNames from 'classnames';

import './Tabs.styles.scss';

type Tab = {
  label: React.ReactNode;
  content: React.ReactNode;
};

type TabsProps = {
  tabs: Tab[];
  className?: string;
};

const Tabs = (props: TabsProps) => {
  const { tabs, className } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const classes = classNames('tabs', className);

  return (
    <div className={classes}>
      <div className='tabs__header'>
        {tabs.map((tab, index) => (
          <div
            className={classNames('tabs__header__tab', {
              'tabs__header__tab--active': index === activeIndex,
            })}
            key={index}
            onClick={() => setActiveIndex(index)}
          >
            {tab.label}
          </div>
        ))}
      </div>
      <div className='tabs__tab-content'>{tabs[activeIndex].content}</div>
    </div>
  );
};

export default Tabs;

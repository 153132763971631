import { useEffect } from 'react';

import { API_PATH } from 'constant';
import { RequsetMethod } from 'enums';
import { useAxios } from 'hooks';

type Error = {
  message: string;
  error: string;
  statusCode: number;
};

export const useEmployeeNumber = () => {
  const { setError, response, request } = useAxios<any, Error>({
    url: API_PATH.USERS,
    method: RequsetMethod.GET,
    onError: (errorParam) => {
      setError(errorParam);
    },
  });

  useEffect(() => {
    request({});
  }, []);

  return response?.data?.totalItems >= 0 ? response?.data.totalItems : '-';
};

// useInfiniteScrollWithAxios.ts

import { AxiosResponse } from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IAxiosParams, useAxios } from './useAxios';
import { parseQueryParams } from 'utils/parseQueryParams';
import { SortingOrder } from 'models/types';

type InfinitePaginationProps<TResponse> = {
  axiosConfig: IAxiosParams<TResponse, any>;
  initialPage?: number;
  limit?: number;
};

function useInfinitePagination<TResponse>(options: InfinitePaginationProps<TResponse>) {
  const { axiosConfig, initialPage = 1, limit } = options;

  const listInnerRef = useRef<HTMLDivElement | null>(null);
  const [currPage, setCurrPage] = useState(initialPage);
  const [dataList, setDataList] = useState<TResponse[]>([]);
  const [lastPage, setLastPage] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [sortByName, setSortByName] = useState(0);

  const { request: axiosRequest, loading } = useAxios({
    ...axiosConfig,
    onResponse(responseParam: AxiosResponse<any>) {
      if (currPage === 1) setDataList(responseParam.data.items);
      else setDataList((prev) => [...prev, ...responseParam.data.items]);

      if (responseParam.data.totalPages === currPage) setLastPage(true);
    },
  });

  useEffect(() => {
    if (currPage === 1) {
      setDataList([]);
    }

    const fetchData = () => {
      const queryParams = {
        ...axiosConfig.params,
        page: currPage,
        search: searchString,
        limit,
      };
      axiosRequest({ queryParams });
    };

    fetchData();
  }, [currPage, searchString, axiosConfig.params, limit]);

  useEffect(() => {
    const fetchData = () => {
      const queryParams =
        sortByName != SortingOrder.UNSORTED
          ? {
              ...axiosConfig.params,
              page: currPage,
              search: searchString,
              limit,
              sort: { firstName: sortByName },
            }
          : {
              ...axiosConfig.params,
              page: currPage,
              search: searchString,
              limit,
            };
      axiosRequest({ queryParams });
    };

    fetchData();
  }, [sortByName]);

  const onScroll = useCallback(() => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + 2.0 + clientHeight >= scrollHeight && !lastPage && currPage) {
        setCurrPage(currPage + 1);
      }
    }
  }, [currPage, lastPage]);

  const loadMoreData = useCallback(() => {
    if (!lastPage) setCurrPage((prevPage) => prevPage + 1);
  }, [lastPage]);

  const setSearch = (search: string) => {
    setSearchString(search);
    setCurrPage(1);
    setLastPage(false);
  };

  return {
    listInnerRef,
    dataList,
    onScroll,
    setSearch,
    loading,
    loadMoreData,
    setSortByName,
  };
}

export default useInfinitePagination;

import React, { FC, useCallback, useEffect, useState } from 'react';
import { SectionBlock } from 'components/layout/SectionBlock';
import useGeneralVacationsTableColumns from './hooks/useGeneralVacationsTableColumns';
import Table from 'components/Table';
import { Button, GeneralVacationModal, YearPicker } from 'components';
import TablePagination from 'components/TablePagination';
import useGeneralVacationsStore from 'pages/NonWorkingDaysPage/stores/useGeneralVacationsStore';
import { useModalState } from 'hooks';
import { PenIcon } from 'assets';
import { TGeneralVacation } from 'models/GeneralVacation';
import { getYear } from 'date-fns';

import './GeneralVacationsTable.styles.scss';

type GeneralVacationsTableProps = {
  vacationAddedRefreshKey: number;
};

const GeneralVacationsTable: FC<GeneralVacationsTableProps> = ({ vacationAddedRefreshKey }) => {
  const { vacations, getVacations, pagination, params, isLoading } = useGeneralVacationsStore();

  const [selectedVacation, setSelectedVacation] = useState<TGeneralVacation | undefined>(undefined);
  const [sort, setSort] = useState(-1);

  const {
    isOpen: editModalOpen,
    openModal: openEditModal,
    closeModal: closeEditModal,
  } = useModalState();

  const handleOpenEditModal = (row: TGeneralVacation) => {
    setSelectedVacation(row);
    openEditModal();
  };

  const handleSortByStartDate = () => {
    setSort(-sort);
  };

  useEffect(() => {
    getVacations({ sort: { startDate: sort } });
  }, [sort]);

  const columns = useGeneralVacationsTableColumns(handleOpenEditModal, handleSortByStartDate, sort);

  useEffect(() => {
    getVacations();
  }, [params, vacationAddedRefreshKey]);

  const onYearChange = useCallback((year: number) => {
    getVacations({ filter: { year }, page: 1 });
  }, []);

  const onPageChange = useCallback((page: number) => {
    getVacations({ page });
  }, []);

  return (
    <SectionBlock
      title='General vacations'
      className='ne-general-vacations'
      loading={isLoading}
      asideFromTitle={
        <YearPicker
          year={Number(params.filter?.year)}
          onYearChange={onYearChange}
          maxYear={getYear(new Date()) + 1}
          minYear={2000}
        />
      }
    >
      <div className='ne-general-vacations__table-container'>
        <Table
          data={vacations}
          prepareData={columns}
          noDataMessage='There are no general vacations added.'
        />
      </div>

      {editModalOpen ? (
        <GeneralVacationModal
          onClose={closeEditModal}
          isOpen={editModalOpen}
          onSuccess={() => {
            getVacations();
          }}
          generalVacation={selectedVacation}
          isEditMode
        />
      ) : undefined}

      <TablePagination
        totalPages={pagination?.totalPages}
        currentPage={pagination?.currentPage}
        onPageChange={onPageChange}
        totalItems={pagination?.totalItems}
        pageSize={params.limit}
      />
    </SectionBlock>
  );
};

export default GeneralVacationsTable;

import { yupResolver } from '@hookform/resolvers/yup';
import { AddIcon } from 'assets';
import { Button, FieldWrapper, Input } from 'components';
import { InputProps } from 'components/Input/Input.component';
import { useAxios } from 'hooks';
import { User } from 'models/User';
import { snackbar } from 'modules';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import {
  EMAIL_REGEX,
  LETTERS_REGEX,
  MSG_EMAIL_INVALID,
  MSG_LETTERS_ONLY,
  MSG_REQUIRED,
  NAME_REGEX,
  PHONE_REGEX_381,
  PHONE_REGEX_NORMAL,
} from 'validation';
import { MSG_NAME_INVALID } from 'validation/messages';
import * as Yup from 'yup';

import './NewEmployeeForm.styles.scss';

type FormEmployee = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
};

const validation = Yup.object().shape({
  firstName: Yup.string()
    .required(MSG_REQUIRED)
    .matches(LETTERS_REGEX, MSG_LETTERS_ONLY)
    .matches(NAME_REGEX, MSG_NAME_INVALID)
    .max(30, 'First name too long'),
  lastName: Yup.string()
    .required(MSG_REQUIRED)
    .matches(LETTERS_REGEX, MSG_LETTERS_ONLY)
    .matches(NAME_REGEX, MSG_NAME_INVALID)
    .max(30, 'Last name too long'),
  email: Yup.string()
    .email(MSG_EMAIL_INVALID)
    .required(MSG_REQUIRED)
    .matches(EMAIL_REGEX, MSG_EMAIL_INVALID),
  phoneNumber: Yup.string().test(
    'phoneNumber',
    'Example: +38160123321 or 060123321, no blank spaces',
    (value) => {
      if (!value) return true;
      return PHONE_REGEX_381.test(value) || PHONE_REGEX_NORMAL.test(value);
    },
  ),
});

const NewEmployeeForm = () => {
  const navigate = useNavigate();

  const methods = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(validation),
  });

  const { loading: loadingUserCreation, request: sendCreateUserRequest } = useAxios<
    User,
    any,
    Partial<User>
  >({
    url: '/users',
    method: 'POST',
    onResponse: (user) => {
      navigate(`/employees/manage-employees/${user.data.id}`);
      snackbar.show({
        message: 'You have succesfully created an employee.',
        type: 'success',
      });
    },
    onError: (errorParam: any) => {
      if (errorParam.response.status === 409) {
        snackbar.show({
          message: 'Employee with those informations already exists.',
          type: 'error',
        });
      }
    },
  });

  const handleSubmit = async (data: FormEmployee) => {
    sendCreateUserRequest({
      payload: {
        ...data,
        phoneNumber: data.phoneNumber
          ? {
              refferenceNumber: '+381',
              number: data.phoneNumber?.startsWith('+381')
                ? data.phoneNumber.substring(4, data.phoneNumber.length)
                : data.phoneNumber.substring(1, data.phoneNumber.length),
            }
          : undefined,
      },
    });
  };

  return (
    <div className='new-employee-form'>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <h1>Create New Employee</h1>
          <div className='new-employee-form__content'>
            <FieldWrapper<InputProps>
              fieldComponent={Input}
              name='firstName'
              label='First name'
              asterix
            />
            <FieldWrapper<InputProps>
              fieldComponent={Input}
              name='lastName'
              label='Last name'
              asterix
            />
            <FieldWrapper<InputProps> fieldComponent={Input} name='email' label='Email' asterix />
            <FieldWrapper<InputProps>
              fieldComponent={Input}
              name='phoneNumber'
              label='Phone number'
            />
          </div>
          <Button type='submit' loading={loadingUserCreation}>
            <AddIcon />
            Create employee
          </Button>
        </form>
      </FormProvider>
    </div>
  );
};

export default NewEmployeeForm;

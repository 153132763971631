import { Button, EmployeesLayout, Input, OverlaySpinner, SearchBar } from 'components';
import useInfinitePagination from 'hooks/useInfinitePagination';
import { IAxiosParams } from 'hooks/useAxios';
import React, { useEffect, useState } from 'react';
import useDebounce from 'hooks/useDebounce';
import { AxiosResponse } from 'axios';
import { AddIcon, GridIcon, NoSearchResultsIcon, SearchIcon, TableIcon } from 'assets';
import { useAxios, useModalState } from 'hooks';
import NewEmployeeModal from './components/NewEmployeeModal/NewEmployeeModal.component';

import './Employees.styles.scss';
import EmployeesTable from './components/EmployeesTable/EmployeesTable.component';
import { SortingOrder } from 'models/types';

const axiosConfig: IAxiosParams<any, any> = {
  url: '/users',
  method: 'GET',
};

const Employees = () => {
  const INITIAL_WINDOW_SIZE = 200;
  const [searchString, setSearchString] = useState('');
  const [employees, setEmployees] = useState<number>();
  const { isOpen: isPopupOpen, closeModal: closePopup, openModal: openPopup } = useModalState();
  const [zoomLevel, setZoomLevel] = useState(INITIAL_WINDOW_SIZE); // State to track zoom level
  const [displayGrid, setDisplayGrid] = useState(false);

  const debounceString = useDebounce(searchString);

  const { request: getEmployees, loading } = useAxios({
    url: '/users',
    method: 'GET',
    onResponse: (responseParam: AxiosResponse<any>) => {
      setEmployees(responseParam.data.totalItems);
    },
  });

  useEffect(() => {
    getEmployees({ queryParams: { search: debounceString } });
  }, [debounceString]);

  const {
    listInnerRef,
    dataList,
    onScroll,
    setSearch,
    loading: loadingPagination,
    loadMoreData,
    setSortByName,
  } = useInfinitePagination({
    axiosConfig,
    initialPage: 1,
    limit: 18,
  });

  useEffect(() => {
    setSearch(debounceString);
  }, [debounceString]);

  useEffect(() => {
    const existingOverlay = document.querySelector('.overlay');
    if (loading || loadingPagination) {
      if (!existingOverlay) OverlaySpinner.show('.panel');
    } else {
      OverlaySpinner.hide('.panel');
    }
  }, [loading, loadingPagination]);

  // Effect hook for detecting zoom changes
  useEffect(() => {
    const detectZoomChange = () => {
      const ratio = window.devicePixelRatio;
      setZoomLevel(Math.round(ratio * 100)); // Update state with the new zoom level
    };

    detectZoomChange(); // Initial check to set zoom level on component mount

    window.addEventListener('resize', detectZoomChange);

    return () => window.removeEventListener('resize', detectZoomChange);
  }, []);

  // manually load more data if the window is zoomed out, since scroll event won't work in that case
  useEffect(() => {
    if (zoomLevel < INITIAL_WINDOW_SIZE) {
      setTimeout(() => {
        loadMoreData();
      }, 500);
    }
  }, [zoomLevel, loadMoreData]);

  const handleSortEmployeesByName = (data: number) => {
    setSortByName(data);
  };

  return (
    <div className='my-employees' onScroll={onScroll} ref={listInnerRef}>
      <div className='my-employees__container'>
        <div className='my-employees__header'>
          <div className='my-employees__main'>
            <h1>
              My employees
              <p>
                <b>{employees ? employees : 0}</b> employees
              </p>
            </h1>
            <Button icon={<AddIcon />} onClick={openPopup} hoverText='Create new employee'>
              Add employee
            </Button>
          </div>
          <div className='my-employees__search'>
            <div className='my-employees__searchbar'>
              <Input
                prefixNode={<SearchIcon />}
                value={searchString}
                onChange={(ev) => setSearchString(ev.target.value)}
                placeholder='Search for an employee...'
                className='search-input'
              />
            </div>
            <div className='my-employees__buttons'>
              <Button
                variant='icon'
                type='button'
                onClick={() => setDisplayGrid(false)}
                hoverText='Show table view'
                className={
                  !displayGrid
                    ? 'my-employees__buttons__button-active'
                    : 'my-employees__buttons__button-inactive'
                }
              >
                <TableIcon />
              </Button>
              <Button
                variant='icon'
                type='button'
                onClick={() => setDisplayGrid(true)}
                hoverText='Show grid view'
                className={
                  displayGrid
                    ? 'my-employees__buttons__button-active'
                    : 'my-employees__buttons__button-inactive'
                }
              >
                <GridIcon />
              </Button>
            </div>
          </div>
        </div>
        <NewEmployeeModal onCloseModal={closePopup} isModalOpen={isPopupOpen} />
        <div className='my-employees__content'>
          {employees === 0 && debounceString === '' && !loading ? (
            <div className='my-employees__no-users'>
              <div className='my-employees__no-users__images'>
                <img
                  src='images/userDefault.png'
                  alt='user'
                  className='my-employees__no-users__images__image-1'
                />
                <img
                  src='images/userDefault.png'
                  alt='user'
                  className='my-employees__no-users__images__image-2'
                />
                <img
                  src='images/userDefault.png'
                  alt='user'
                  className='my-employees__no-users__images__image-3'
                />
              </div>
              <p className='my-employees__no-users__description'>
                Seems like you didn’t add your employees. Just click on
                <b>
                  <i> + Add employee </i>
                </b>
                <br /> to add the first one. Also, don’t forget to send them an invite to join you
                and
                <br /> access their profile.
              </p>
            </div>
          ) : employees === 0 && debounceString !== '' ? (
            <div className='my-employees__empty-search'>
              <NoSearchResultsIcon />
              There are no employees under that name.
            </div>
          ) : (
            <>
              {!displayGrid && (
                <div className='my-employees__layout'>
                  <div className='my-employees__layout__header'></div>
                  <EmployeesTable employees={dataList} onSortChange={handleSortEmployeesByName} />
                </div>
              )}
              <div>{displayGrid && <EmployeesLayout employees={dataList} />}</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Employees;

import { EmployeeDocument } from 'models/EmployeeDocument';
import { TParams } from 'models/TParams';
import { PaginationType } from 'models/types';
import { httpClient } from 'modules';
import parseAxiosError from 'utils/parseAxiosError';
import { parseQueryParams } from 'utils/parseQueryParams';

async function getEmployeeDocuments(employeeId: string, params: TParams) {
  return httpClient.get<PaginationType<EmployeeDocument>>(`/files/user/${employeeId}`, {
    params: parseQueryParams(params),
  });
}

async function addEmployeeDocument(employeeId: string, formData: FormData) {
  return httpClient.post<EmployeeDocument>(`/files/user/${employeeId}`, formData);
}

async function updateEmployeeDocument(documentId: string, formData: FormData) {
  return httpClient.put<EmployeeDocument>(`/files/${documentId}`, formData);
}

async function removeEmployeeDocument(documentId: string) {
  return httpClient.delete<EmployeeDocument>(`/files/${documentId}`);
}

export default {
  getEmployeeDocuments,
  addEmployeeDocument,
  updateEmployeeDocument,
  removeEmployeeDocument,
};

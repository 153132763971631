import classNames from 'classnames';
import ncoded from 'assets/ncoded.jpeg';
import { Button } from 'components/Button';
import { useAuth } from 'context';
import { UserRole } from 'enums';
import Menu from './components/Menu';
import LogoutModal from './components/LogoutModal/LogoutModal.component';
import { BookReader, Logout, SidebarIcon } from 'assets';
import { useModalState } from 'hooks';
import { Avatar } from 'components/Avatar';
import React, { useEffect, useState } from 'react';
import './Sidebar.styles.scss';

const Sidebar = () => {
  const { user, setToken } = useAuth();
  const { firstName, lastName, role, profileImageUrl } = user || {};
  const { isOpen: isPopupOpen, closeModal: closePopup, openModal: openPopup } = useModalState();

  const SM_BREAKPOINT = 768;

  const [isCollapsed, setIsCollapsed] = useState(window.innerWidth < SM_BREAKPOINT);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleLogout = () => {
    setToken(null);
  };

  const handleCollapse = () => {
    if (window.innerWidth <= SM_BREAKPOINT) {
      setIsCollapsed(!isCollapsed);
    }
  };

  const handleMenuItemClick = () => {
    if (window.innerWidth <= SM_BREAKPOINT) {
      setIsCollapsed(true);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      if (window.innerWidth > SM_BREAKPOINT) {
        setIsCollapsed(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div>
      {!isCollapsed && (
        <div className='sidebar'>
          <div className='sidebar__nav' onClick={handleCollapse}>
            <div className='sidebar__nav__img-wrapper'>
              <img src={ncoded} alt='ncoded' className='header-sidebar__ncoded-img' />
            </div>
            <SidebarIcon visibility={screenWidth <= SM_BREAKPOINT ? 'visible' : 'hidden'} />
          </div>
          {user && (
            <>
              <div className='sidebar__welcome'>
                <span className='sidebar__welcome__text'>
                  Welcome, <span className='sidebar__welcome__role'>{firstName}</span>!
                </span>
              </div>
              <div className='sidebar__user'>
                <Avatar imageUrl={profileImageUrl} alt='profile-image' />
                <div className='sidebar__user__name'>
                  {role === UserRole.ADMIN && <BookReader className='sidebar__book' />}
                  <p
                    className={classNames('sidebar__firstname', {
                      'sidebar__firstname--employee': role === UserRole.EMPLOYEE,
                    })}
                  >
                    {firstName}
                  </p>
                  <p
                    className={classNames('sidebar__lastname', {
                      'sidebar__lastname--employee': role === UserRole.EMPLOYEE,
                    })}
                  >
                    {lastName}
                  </p>
                </div>
              </div>
              <Menu userRole={user.role} onMenuItemClick={handleMenuItemClick} />
              <div className='sidebar__open-logout'>
                <Button onClick={openPopup} className='sidebar__button-open'>
                  <Logout className='sidebar__logout-img' />
                  Logout
                </Button>
              </div>
            </>
          )}
          <LogoutModal onRequestClose={closePopup} isOpen={isPopupOpen} onLogout={handleLogout} />
        </div>
      )}

      {isCollapsed && (
        <div className='header-sidebar' onClick={handleCollapse}>
          <div className='header-sidebar__img-wrapper'>
            <img src={ncoded} alt='ncoded' className='header-sidebar__ncoded-img' />
          </div>
          <SidebarIcon className='menu-icon' />
        </div>
      )}
    </div>
  );
};

export default Sidebar;

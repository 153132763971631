import api from 'api';
import { create } from 'zustand';
import { TMultiplierTableRow } from '../types/TMultiplierTableRow';
import { Pagination } from 'models/types';
import { TParams } from 'models/TParams';
import { MultiplierFormValues } from '../components/MulitpliersSettings/components/MultiplierForm/MultiplierForm.component';
import parseAxiosError from 'utils/parseAxiosError';
import useGlobalMiltipliersStore from 'store/useGlobalMiltipliersStore';

type Params = TParams & {
  search?: string;
  populate: string;
};

interface EmploymentState {
  isLoading: boolean;
  removingMultiplier: boolean;
  multipliers: TMultiplierTableRow[];
  error?: string;
  params: Params;
  pagination: Pagination;
  getMultipliers: () => void;
  applyAndSearch: (params: Partial<Params>) => void;
  addMultiplierApi: (
    data: MultiplierFormValues,
    onSuccess?: (multiplier: TMultiplierTableRow) => void,
    onError?: (errorMessage: string) => void,
  ) => Promise<void>;
  removeMultiplier: (
    multiplierId: string,
    onSuccess?: (multiplierId: string) => void,
    onError?: (errorMessage: string) => void,
  ) => Promise<void>;
  updateMultiplier: (
    multiplierId: string,
    data: MultiplierFormValues,
    onSuccess?: () => void,
    onError?: (errorMessage: string) => void,
  ) => Promise<void>;
}

const useMultipliersStore = create<EmploymentState>()((set, get) => ({
  isLoading: false,
  removingMultiplier: false,
  params: {
    limit: 15,
    page: 1,
    populate: 'users',
  },
  pagination: { totalItems: 0, totalPages: 0, currentPage: 1, pageSize: 15 },
  multipliers: [],
  getMultipliers: async () => {
    const params = get().params;
    try {
      set({ isLoading: true });
      const { data } = await api.admin.multipliers.getMulitpliers(params);
      const { items, ...pagination } = data;
      set({ isLoading: false, multipliers: items, pagination });
    } catch (err) {
      set({ isLoading: false });
    }
  },
  applyAndSearch: (params: Partial<Params>) => {
    set({ params: { ...get().params, ...params } });
    get().getMultipliers();
  },

  addMultiplierApi: async (
    data: MultiplierFormValues,
    onSuccess?: (multiplier: TMultiplierTableRow) => void,
    onError?: (errorMessage: string) => void,
  ) => {
    const state = get();
    try {
      const { data: mulitplier } = await api.admin.multipliers.createMultplier(data);
      set({ multipliers: [...state.multipliers, mulitplier] });
      useGlobalMiltipliersStore.getState().getMultipliers();
      onSuccess?.(mulitplier);
    } catch (err) {
      console.log(err);
      const errrorMessage = parseAxiosError(err as any);
      onError?.(errrorMessage);
    }
  },
  updateMultiplier: async (
    multiplierId: string,
    data: MultiplierFormValues,
    onSuccess?: () => void,
    onError?: (errorMessage: string) => void,
  ) => {
    const state = get();
    try {
      const { data: updatedMultiplier } = await api.admin.multipliers.updateMultiplier(
        multiplierId,
        data,
      );
      set({
        multipliers: state.multipliers.map((mul) =>
          mul?.id === multiplierId ? updatedMultiplier : mul,
        ),
      });
      useGlobalMiltipliersStore.getState().getMultipliers();
      onSuccess?.();
    } catch (err) {
      const errrorMessage = parseAxiosError(err as any);
      onError?.(errrorMessage);
    }
  },
  removeMultiplier: async (
    multiplierId: string,
    onSuccess?: (multiplierId: string) => void,
    onError?: (errorMessage: string) => void,
  ) => {
    const state = get();
    try {
      set({ removingMultiplier: true });
      const { data: mulitplier } = await api.admin.multipliers.removeMultiplier(multiplierId);
      set({
        multipliers: state.multipliers.filter((mul) => mul.id !== multiplierId),
        pagination: {
          ...state.pagination,
          totalItems: state.pagination.totalItems - 1,
        },
        removingMultiplier: false,
      });

      useGlobalMiltipliersStore.getState().getMultipliers();

      onSuccess?.(mulitplier);
    } catch (err) {
      console.log(err);
      const errrorMessage = parseAxiosError(err as any);
      onError?.(errrorMessage);
      set({ removingMultiplier: false });
    }
  },
}));

export default useMultipliersStore;

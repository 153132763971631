import { CloseIcon, PenIcon } from 'assets';
import classNames from 'classnames';
import { useModalState } from 'hooks';
import React, { FC, useCallback } from 'react';
import { Button, Modal } from 'components';
import { TMultiplierTableRow } from 'pages/SettingsPage/types/TMultiplierTableRow';
import RemoveMultiplierModal from '../RemoveMultiplierModal';
import useMultipliersStore from 'pages/SettingsPage/store/useMultipliersStore';
import { snackbar } from 'modules';
import MultiplierForm from '../MultiplierForm';
import { MultiplierFormValues } from '../MultiplierForm/MultiplierForm.component';
import './MultipliersActions.styles.scss';

type MultipliersActionsProps = {
  className?: string;
  multiplier: TMultiplierTableRow;
};

const MultipliersActions: FC<MultipliersActionsProps> = (props) => {
  const { className, multiplier } = props;

  const classes = classNames('nce-employee-document-actions', className);

  const {
    isOpen: removeModalOpen,
    openModal: openRemoveModal,
    closeModal: closeRemoveModal,
  } = useModalState();

  const { removeMultiplier, removingMultiplier, updateMultiplier } = useMultipliersStore();

  const {
    isOpen: editModalOpen,
    openModal: openEditModal,
    closeModal: closeEditModal,
  } = useModalState();

  const onRemoveSuccess = useCallback(() => {
    snackbar.show({
      message: 'You have successfully deleted a multiplier.',
      type: 'success',
    });
    closeRemoveModal();
  }, [closeRemoveModal]);

  const onRemoveError = useCallback((errorMessage: string) => {
    snackbar.show({ message: errorMessage, type: 'error' });
  }, []);

  const handleMultiplierRemove = useCallback(() => {
    return removeMultiplier(multiplier.id, onRemoveSuccess, onRemoveError);
  }, [removeMultiplier, multiplier?.id]);

  const onUpdateSuccess = useCallback(() => {
    snackbar.show({
      message: 'You have successfully updated a multiplier.',
      type: 'success',
    });
    closeEditModal();
  }, [closeEditModal]);

  const onUpdateError = useCallback((errorMessage: string) => {
    snackbar.show({ message: errorMessage, type: 'error' });
  }, []);

  const handleMultiplierUpdate = useCallback(
    (data: MultiplierFormValues) => {
      return updateMultiplier(multiplier.id, data, onUpdateSuccess, onUpdateError);
    },
    [updateMultiplier, multiplier?.id, onUpdateSuccess, onUpdateError],
  );

  return (
    <div className={classes}>
      <Button onClick={() => {}} variant='icon'>
        <PenIcon className='action-edit' onClick={openEditModal} />
      </Button>
      <Button variant='icon'>
        <CloseIcon className='action-remove' onClick={openRemoveModal} />
      </Button>

      <RemoveMultiplierModal
        onSubmit={handleMultiplierRemove}
        isOpen={removeModalOpen}
        onRequestClose={closeRemoveModal}
        loading={removingMultiplier}
      />

      <Modal
        isOpen={editModalOpen}
        onRequestClose={closeEditModal}
        className='multiplier-form-modal'
        hasCloseIcon
      >
        <MultiplierForm
          title='Edit multiplier'
          onSubmit={handleMultiplierUpdate}
          buttonText={'Edit'}
          multiplier={multiplier}
        />
      </Modal>
    </div>
  );
};

export default MultipliersActions;

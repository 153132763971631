import axios, { InternalAxiosRequestConfig } from 'axios';
import env from 'env';

const httpClient = axios.create({
  baseURL: env.SERVER_ENDPOINT,
});

httpClient.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const token = localStorage.getItem('token');

  if (token) config.headers.Authorization = `Bearer ${token}`;

  return config;
});

export { httpClient };

import api from 'api';
import { Pagination, SortingOrder } from 'models/types';
import { create } from 'zustand';
import { TEmployeeVacationsParams } from '../types/TEmployeeVacationsParams';
import { EmployeeNonWorkingDays } from 'models/NonWorkingDays';
import { mapUserToNonWorkingDays } from '../utils/utils';
import parseAxiosError from 'utils/parseAxiosError';

interface GeneralVacationsState {
  isLoading: boolean;
  vacations: EmployeeNonWorkingDays[];
  pagination: Pagination;
  params: TEmployeeVacationsParams;
  error?: string;
  getVacations: (params?: Partial<TEmployeeVacationsParams>) => void;
  applyAndSearch: (params: Partial<TEmployeeVacationsParams>) => void;
  updateNonWorkingDays: (
    employeeId: string,
    nonWorkingDays: number,
    onSuccess?: () => void,
    onError?: (errorMessage: string) => void,
  ) => Promise<void>;
}

const useEmployeeVacationsStore = create<GeneralVacationsState>()((set, get) => ({
  isLoading: false,
  isRemovingDocument: false,
  isUpdatingDocument: false,
  vacations: [],
  params: {
    limit: 15,
    page: 1,
    populate: ['firstContract', 'lastContract'],
    search: undefined,
    sort: {
      firstName: SortingOrder.ASCENDING,
      lastName: SortingOrder.ASCENDING,
    },
  },
  pagination: {
    totalItems: 0,
    currentPage: 0,
    totalPages: 0,
    pageSize: 5,
  },
  getVacations: async (params?: Partial<TEmployeeVacationsParams>) => {
    try {
      set({ isLoading: true });
      let finalParams: TEmployeeVacationsParams;

      if (params) {
        finalParams = { ...get().params, ...params };
        set({ params: finalParams });
      } else {
        finalParams = get().params;
      }

      const { data } = await api.admin.generalVacations.getEmployeesVacations(finalParams);
      const { items, ...pagination } = data;
      set({
        isLoading: false,
        vacations: items.map((item) => mapUserToNonWorkingDays(item)),
        pagination,
      });
    } catch (err) {
      set({ isLoading: false });
    }
  },
  applyAndSearch: (params: Partial<TEmployeeVacationsParams>) => {
    set({ params: { ...get().params, ...params } });
    get().getVacations();
  },
  updateNonWorkingDays: async (
    employeeId: string,
    nonWorkingDays: number,
    onSuccess?: () => void,
    onError?: (errorMessage: string) => void,
  ) => {
    const state = get();
    try {
      const { data: updateRecord } = await api.admin.nonWorkingDays.updateEmployeeNonWorkingDays(
        employeeId,
        nonWorkingDays,
      );
      set({
        vacations: state.vacations.map((item) =>
          item.employeeId === employeeId ? mapUserToNonWorkingDays(updateRecord) : item,
        ),
      });
      onSuccess?.();
    } catch (err) {
      const errorMessage = parseAxiosError(err as any);
      onError?.(errorMessage);
    }
  },
}));

export default useEmployeeVacationsStore;

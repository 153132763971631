import { AddIcon, ChevronIcon } from 'assets';
import { Button } from 'components';
import Table from 'components/Table';
import { SectionBlock } from 'components/layout/SectionBlock';
import { useModalState } from 'hooks';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import useEmploymentInfoTableColumns from './hooks/useEmploymentInfoTableColumns';
import { EmployeeDocument } from 'models/EmployeeDocument';
import TablePagination from 'components/TablePagination';
import useAdminEmploymentStore from 'pages/EmployeeProfilePage/store/useAdminEmploymentStore';
import EmployeeContext from 'pages/EmployeeProfilePage/context/EmploeeContext';
import useGlobalMiltipliersStore from 'store/useGlobalMiltipliersStore';
import { DocumentModal } from './components/DocumentModal';
import { snackbar } from 'modules';
import EmployeeSalaryVariables from '../EmployeeSalaryVariables/EmployeeSalaryVariables.component';
import { useAuth } from 'context';
import { UserRole } from 'enums';
import EmployeeNonWorkingDays from './components/EmployeeNonWorkingDays/EmployeeNonWorkingDays.component';

import './EmploymentInformation.style.scss';
import TimeOffsTable from 'pages/TimeOffsPage/components/TimeOffsTable/TimeOffsTable.component';

const EmploymentInformation = () => {
  const { employee, updateEmployee } = useContext(EmployeeContext);
  const { openModal, isOpen, closeModal } = useModalState();
  const { getDocuments, isLoading, documents, pagination, addDocumentApi, params } =
    useAdminEmploymentStore();

  const { multipliers } = useGlobalMiltipliersStore();
  const { user } = useAuth();

  const [documentsOpen, setDocumentsOpen] = useState(true);
  const [vacationsOpen, setVacationsOpen] = useState(true);

  useEffect(() => {
    if (employee?.id) getDocuments(employee.id);
  }, [employee?.id]);

  useEffect(() => {
    if (documents.length === 0) {
      // all documents are removed -> remove the multiplier and lastContract of the Employee
      updateEmployee({ ...employee, multiplier: undefined, lastContract: undefined });
    } else if (
      documents.length === 1 &&
      documents[0] &&
      !employee.multiplier &&
      !employee.lastContract
    ) {
      getDocuments(employee.id);
      // there is just one document, but Employee is still not updated? -> update the Employee
      updateEmployee({
        ...employee,
        multiplier: multipliers.filter((mul) => {
          return mul.id === documents[0].multiplierId;
        })[0],
        lastContract: {
          id: documents[0]._id,
          startDate: documents[0].startDate,
          endDate: documents[0]?.endDate,
          userId: documents[0].userId as string,
          grossSalary: documents[0].grossSalary,
        },
      });
    } else if (
      !employee.multiplier ||
      !employee.lastContract ||
      (documents[0].multiplierId !== employee.multiplier?.id &&
        documents[0]._id !== employee.lastContract?.id)
    ) {
      if (pagination.currentPage === 1) {
        if (employee?.id) getDocuments(employee.id);
        // new document is added documents[0] -> update the Employee
        updateEmployee({
          ...employee,
          multiplier: multipliers.filter((mul) => {
            return mul.id === documents[0].multiplierId;
          })[0],
          lastContract: {
            id: documents[0]._id,
            startDate: documents[0].startDate,
            endDate: documents[0]?.endDate,
            userId: documents[0].userId as string,
            grossSalary: documents[0].grossSalary,
          },
        });
      }
    }
  }, [documents, documents?.length]);

  const columns = useEmploymentInfoTableColumns();

  const changePageNumber = useCallback(
    (page: number) => {
      if (employee?.id) {
        getDocuments(employee?.id, { page });
      }
    },
    [employee?.id],
  );

  const onAddSuccess = useCallback(() => {
    snackbar.show({
      message: 'Document saved',
      type: 'success',
    });
    closeModal();
  }, [closeModal]);

  const onAddError = useCallback((errorMessage: string) => {
    snackbar.show({ message: errorMessage, type: 'error' });
  }, []);

  const handleAddDocument = useCallback(
    (formData: FormData) => {
      if (employee?.id) return addDocumentApi(employee?.id, formData, onAddSuccess, onAddError);
    },
    [employee?.id, onAddSuccess, onAddError],
  );

  return (
    <div className='employment-info'>
      <SectionBlock
        title={
          <p
            className={`employment-info__documents-section__accordion__${documentsOpen ? 'open' : 'closed'}`}
            onClick={() => setDocumentsOpen(!documentsOpen)}
          >
            <Button variant='icon' type='button' onClick={() => setDocumentsOpen(!documentsOpen)}>
              <ChevronIcon />
            </Button>
            Employment information documents
          </p>
        }
        loading={isLoading}
        className='employment-info__documents-section'
        asideFromTitle={
          documentsOpen && (
            <Button onClick={openModal} className='employment-info__add-button'>
              <AddIcon />
              Add document
            </Button>
          )
        }
        displayChildren={documentsOpen}
      >
        <div className='employment-info__table-container'>
          <Table<EmployeeDocument>
            data={documents}
            prepareData={columns}
            noDataMessage={
              user?.role === UserRole.ADMIN
                ? "The employee doesn't have any documents uploaded yet. 📄"
                : "Welcome to Ncoded! 👋 Here you'll find your employment related documents once the admin adds them."
            }
          />
        </div>

        {pagination?.totalItems > 0 && (
          <TablePagination
            totalPages={pagination?.totalPages}
            currentPage={pagination?.currentPage}
            onPageChange={changePageNumber}
            totalItems={pagination?.totalItems}
            pageSize={params.limit}
          />
        )}
        {isOpen && (
          <DocumentModal
            isOpen={isOpen}
            onRequestClose={closeModal}
            userId={employee?.id || ''}
            multipliers={multipliers}
            onSubmit={handleAddDocument}
          />
        )}
      </SectionBlock>
      <EmployeeNonWorkingDays />
      <div className='employment-info__timeoffs-table'>
        <h1
          className={`employment-info__timeoffs-table__accordion--${vacationsOpen ? 'open' : 'closed'}`}
          onClick={() => setVacationsOpen(!vacationsOpen)}
        >
          <Button variant='icon' type='button' onClick={() => setVacationsOpen(!vacationsOpen)}>
            <ChevronIcon />
          </Button>
          Vacation requests
        </h1>
        {vacationsOpen && <TimeOffsTable isSearchVisible={false} />}
      </div>
      {documents.length > 0 && <EmployeeSalaryVariables />}
    </div>
  );
};

export default EmploymentInformation;

import React, { FC, useEffect, useState } from 'react';

import { getYear } from 'date-fns';
import { SectionBlock } from 'components/layout/SectionBlock';
import Table from 'components/Table';
import TablePagination from 'components/TablePagination';
import usePaginatedData from 'hooks/usePaginatedData';
import { DaysOffType } from 'models/DaysOff';
import { YearPicker } from 'components';

import './DaysOffTable.styles.scss';
import { useDaysOffTableColumns } from 'pages/DaysOffPage/hooks/useDaysOffTableColumns';
import { SortingOrder } from 'models/types';
import { toggleSortingOrder } from 'utils/toggleSortingOrder';

type DaysOffProps = {
  startYear?: number;
};

const DaysOffTable: FC<DaysOffProps> = ({ startYear }) => {
  const {
    data: userDaysOff,
    loading: loadingDaysOff,
    pagination,
    params,
    setParams,
    changePageNumber,
    onYearChange,
  } = usePaginatedData<DaysOffType>(
    { sort: { startDate: -1 }, limit: 10, page: 1, filter: { year: getYear(new Date()) } },
    '/general-vacations',
  );

  const [sortByStartDate, setSortByStartDate] = useState(SortingOrder.UNSORTED);

  const handleSortByStartDate = () => {
    setSortByStartDate(toggleSortingOrder(sortByStartDate));
  };

  const daysOffTableColumns = useDaysOffTableColumns(handleSortByStartDate, sortByStartDate);

  useEffect(() => {
    if (sortByStartDate != SortingOrder.UNSORTED) {
      setParams((prev) => ({ ...prev, page: 1, sort: { startDate: sortByStartDate } }));
    } else {
      setParams((prev) => ({ ...prev, page: 1, sort: { createdAt: SortingOrder.ASCENDING } }));
    }
  }, [sortByStartDate]);

  return (
    <SectionBlock
      title='Days off'
      loading={loadingDaysOff}
      className='days-off-table'
      asideFromTitle={
        <YearPicker
          year={params?.filter?.year || getYear(new Date())}
          onYearChange={onYearChange}
          maxYear={getYear(new Date()) + 1}
          minYear={startYear}
        />
      }
    >
      <div className='days-off-table__table-container'>
        <Table
          data={userDaysOff}
          prepareData={daysOffTableColumns}
          noDataMessage="Here, you'll find general vacations, including holidays and collective breaks, added by your admin."
        />
      </div>

      {pagination && (
        <TablePagination
          totalPages={pagination?.totalPages}
          currentPage={pagination?.currentPage}
          totalItems={pagination?.totalItems}
          pageSize={pagination?.pageSize}
          onPageChange={changePageNumber}
        />
      )}
    </SectionBlock>
  );
};

export default DaysOffTable;

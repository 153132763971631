import React from 'react';
import { APP_URI } from 'config';
import { useAuth } from 'context';
import { Navigate, Outlet } from 'react-router-dom';
import { Sidebar } from 'components/Sidebar';
import './ProtectedRoutes.styles.scss';

export const ProtectedRoute = () => {
  const { token } = useAuth();

  if (!token) {
    return <Navigate to={APP_URI.login} />;
  }

  return (
    <div className='panel' style={{ display: 'flex', minHeight: '100vh' }}>
      <Sidebar />
      <Outlet />
    </div>
  );
};

import { UploadFile } from 'components/Dropzone/Dropzone.component';
import { useCallback, useEffect, useState } from 'react';
import { FileRejection } from 'react-dropzone';

import noImagePlaceholder from 'assets/No-Image-Placeholder.svg.png';

type FileUploadProps = {
  value: UploadFile[];
  onChange: (files: UploadFile[]) => void;
  isProfilePicture?: boolean;
  multiple?: boolean;
};

const useFileUpload = ({ onChange, value, isProfilePicture, multiple }: FileUploadProps) => {
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const handleAccept = useCallback(
    (files: File[]) => {
      onChange([
        ...(multiple ? value : []),
        ...files.map((file) => ({
          name: file.name,
          type: file.type,
          blobURL: URL.createObjectURL(file),
        })),
      ]);
      setErrorMessages([]);
    },
    [multiple, onChange, value],
  );
  const handleRemove = useCallback(
    (url: string) => {
      if (!isProfilePicture) {
        onChange(value.filter(({ blobURL }) => blobURL !== url));
      } else {
        onChange([
          {
            blobURL: noImagePlaceholder,
            name: 'missingImage',
            type: 'image/png',
          },
        ]);
      }
      URL.revokeObjectURL(url);
      setErrorMessages([]);
    },
    [isProfilePicture, onChange, value],
  );

  const handleReject = useCallback((rejectedFiles: FileRejection[]) => {
    const errors = rejectedFiles.map(({ errors }) => errors.map((error: any) => error.message));
    const errorArray: string[] = [];
    errors.forEach((errorArr) => errorArr.forEach((error: any) => errorArray.push(error)));

    setErrorMessages(errorArray);
  }, []);

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return value.forEach(({ blobURL }) => URL.revokeObjectURL(blobURL));
  }, []);

  return {
    handleAccept,
    handleReject,
    handleRemove,
    errorMessages,
  };
};

export default useFileUpload;

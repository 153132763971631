import { Modal } from 'components/Modal';
import NewEmployeeForm from '../NewEmployeeForm/NewEmployeeForm.component';
import React, { FC } from 'react';

import './NewEmployeeModal.styles.scss';

type NewEmployeeModalProps = {
  onCloseModal: () => void;
  isModalOpen: boolean;
};

const NewEmployeeModal: FC<NewEmployeeModalProps> = ({ onCloseModal, isModalOpen }) => {
  return (
    <Modal
      onRequestClose={onCloseModal}
      isOpen={isModalOpen}
      hasCloseIcon
      className='new-employee-modal'
    >
      <NewEmployeeForm />
    </Modal>
  );
};

export default NewEmployeeModal;

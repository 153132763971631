import { Button } from 'components';
import { TableColumnItem } from 'components/Table/Table.component';
import { VacationRequestData } from 'models/VacationRequest';
import DateService from 'services/Date.service';
import React from 'react';
import { InfoIcon, SortIcon, UnsortedIcon } from 'assets';

export const useTimeOffTableColumns = (
  onRowClick: (data: VacationRequestData) => void,
  handleSortByStartDate: () => void,
  handleSortByRequestedOn: () => void,
  sortByStartDate: number,
  sortByRequestedOn: number,
) => {
  const items: TableColumnItem<VacationRequestData>[] = [
    {
      columnLabel: 'Employee',
      columnName: 'employee',
      prepareCell: (data) => data?.user.firstName + ' ' + data?.user.lastName,
    },
    {
      columnLabel: (
        <p className={`sort-icon-${sortByStartDate}`}>
          Start
          <Button variant='icon' onClick={() => handleSortByStartDate()}>
            {sortByStartDate === 0 && <UnsortedIcon />}
            {sortByStartDate !== 0 && <SortIcon />}
          </Button>
        </p>
      ),
      columnName: 'start',
      prepareCell: (data) => DateService.formatDate(data.startDate),
    },
    {
      columnLabel: 'End',
      columnName: 'end',
      prepareCell: (data) => DateService.formatDate(data.endDate),
    },
    {
      columnLabel: 'Days',
      columnName: 'days',
      prepareCell: (data) => data?.workingDays,
    },
    {
      columnLabel: (
        <p className={`sort-icon-${sortByRequestedOn}`}>
          Requested on
          <Button variant='icon' onClick={() => handleSortByRequestedOn()}>
            {sortByRequestedOn === 0 && <UnsortedIcon />}
            {sortByRequestedOn !== 0 && <SortIcon />}
          </Button>
        </p>
      ),
      columnName: 'requested_on',
      prepareCell: (data) => DateService.formatDate(data.createdAt),
    },
    {
      columnLabel: 'Status',
      columnName: 'status',
      prepareCell: (data) => data?.status,
    },
    {
      columnLabel: 'Actions',
      columnName: 'actions',
      prepareCell: (data) => {
        return (
          <div>
            <Button type='button' onClick={() => onRowClick(data)}>
              <InfoIcon />
            </Button>
          </div>
        );
      },
    },
  ];
  return items;
};

import { PenIcon, SalaryIcon } from 'assets';
import { Button } from 'components/Button';
import { Salary } from 'models/Salary';
import React, { FC } from 'react';
import { formatNumber } from 'utils/formatNumber';

import './SalaryVariablesCard.styles.scss';
import { SectionBlock } from 'components/layout/SectionBlock';
import { formatCurrency } from 'utils/formatCurrency';

type SalaryVariablesCardProps = {
  multiplierName: string;
  grossSalary: Salary;
  onEditButtonClicked: () => void;
};

const SalaryVariablesCard: FC<SalaryVariablesCardProps> = (props) => {
  const { multiplierName, grossSalary, onEditButtonClicked } = props;

  return (
    <SectionBlock
      title={
        <>
          <div className='salary-icon'>
            <SalaryIcon />
          </div>
          Salary variables
        </>
      }
      className='salary-variables-card'
      asideFromTitle={
        <Button
          variant='icon'
          onClick={onEditButtonClicked}
          className='salary-variables-card__button'
        >
          <PenIcon />
        </Button>
      }
    >
      <div className='salary-variables-card__content'>
        <div className='salary-variables-card__row'>
          <b>Used multiplier:</b>
          <p>{multiplierName}</p>
        </div>
        <div className='salary-variables-card__row'>
          <b>Gross salary:</b>
          <p>
            {formatNumber(grossSalary.amount, 2)} {formatCurrency(grossSalary.currency)()}
          </p>
        </div>
      </div>
    </SectionBlock>
  );
};

export default SalaryVariablesCard;

import employee from './employee';
import generalVacations from './generalVacations';
import multipliers from './multipliers';
import nonWorkingDays from './nonWorkingDays';

export default {
  nonWorkingDays,
  generalVacations,
  multipliers,
  employee,
};

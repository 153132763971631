import { yupResolver } from '@hookform/resolvers/yup';
import { Modal } from 'components/Modal';
import { EmployeeNonWorkingDays } from 'models/NonWorkingDays';
import React, { FC, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { MSG_REQUIRED_FIELD } from 'validation';
import * as Yup from 'yup';
import { EditNonWorkingDaysForm } from './components';

import './EditNonWorkingDays.styles.scss';

type EditNonWorkingDaysProps = {
  data: EmployeeNonWorkingDays;
  isOpen?: boolean;
  onSubmit: (value: number) => Promise<void>;
  onClose: () => void;
  displayAsModal?: boolean;
};

export type EditNonWorkingDaysValue = {
  value: number;
  count?: number;
  nonWorkingDays?: number;
};

const validation = () =>
  Yup.object().shape({
    value: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required(MSG_REQUIRED_FIELD)
      .moreThan(-1),
  });

const EditNonWorkingDays: FC<EditNonWorkingDaysProps> = ({
  data,
  isOpen = false,
  onClose,
  onSubmit,
}) => {
  const methods = useForm<EditNonWorkingDaysValue>({
    mode: 'onTouched',
    resolver: yupResolver(validation()),
    values: {
      value: data.nonWorkingDays.vacationDaysCurrentYear,
    },
  });

  const handleSubmit = useCallback(
    (values: EditNonWorkingDaysValue) => {
      return onSubmit(values.value);
    },
    [onSubmit],
  );

  // Reset form when the modal opens or the `data` changes
  useEffect(() => {
    if (isOpen) {
      methods.reset({
        value: data.nonWorkingDays.vacationDaysCurrentYear,
      });
    }
  }, [isOpen, data, methods.reset]);

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className='edit-nwd-modal' hasCloseIcon>
      <EditNonWorkingDaysForm
        employee={data.employee}
        nonWorkingDays={data.nonWorkingDays}
        onSubmit={handleSubmit}
        isLoading={methods.formState.isSubmitting}
        methods={methods}
        closeModal={onClose}
      />
    </Modal>
  );
};

export default EditNonWorkingDays;

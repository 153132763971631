import { FieldWrapper } from 'components/FieldWrapper';
import ImageUpload, { ImageUploadProps } from 'components/ImageUpload/ImageUpload.component';
import { DropzoneOptions } from 'react-dropzone';
import { MB_TO_BYTE } from 'constant';
import Input, { InputProps } from 'components/Input/Input.component';
import { Button } from 'components/Button';
import { FormProvider, useForm } from 'react-hook-form';
import React, { useCallback, useEffect, useState } from 'react';
import noImagePlaceholder from 'assets/No-Image-Placeholder.svg.png';
import { useAuth } from 'context';
import { UploadFile } from 'components/Dropzone';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAxios } from 'hooks';
import { uploadPicture } from 'pages/ProfilePage/utils/uploadProfilePicture';
import { MSG_REQUIRED, NAME_REGEX, PHONE_REGEX_381, PHONE_REGEX_NORMAL } from 'validation';
import {
  MSG_NAME_INVALID,
  MSG_PHONE_NUMBER,
  MSG_PHONE_NUMBER_DUPLICATE,
} from 'validation/messages';
import { OverlaySpinner } from 'components/OverlaySpinner';
import { User } from 'models/User';
import './PersonalInformation.styles.scss';
import { snackbar } from 'modules';

const dropzoneConfig: DropzoneOptions = {
  maxFiles: 1,
  multiple: false,
  maxSize: 20 * MB_TO_BYTE,
  accept: {
    'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
  },
};

type PersonalInformationForm = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
  profilePicture: UploadFile[];
  birthDate: Date | string;
  address: string;
};

export const customResolver = (schema: any) => {
  return (values: any, context: any, options: any) => {
    return yupResolver(schema)(values, context, options);
  };
};

const validation = Yup.object().shape({
  firstName: Yup.string().required(MSG_REQUIRED).matches(NAME_REGEX, MSG_NAME_INVALID).max(32),
  lastName: Yup.string().required(MSG_REQUIRED).matches(NAME_REGEX, MSG_NAME_INVALID).max(32),
  email: Yup.string(),
  phoneNumber: Yup.string().test('phoneNumber', MSG_PHONE_NUMBER, (value) => {
    if (!value) return true;
    return PHONE_REGEX_381.test(value) || PHONE_REGEX_NORMAL.test(value);
  }),
});

const initialValues = (user: User) => ({
  firstName: user?.firstName || '',
  lastName: user?.lastName || '',
  email: user?.email || '',
  phoneNumber: user?.phoneNumber?.refferenceNumber.concat(user?.phoneNumber.number) || '',
  address: user?.address || '',
  // TODO
  // birthDate: dayjs(user?.birthDate) || '',
  profilePicture: [
    {
      blobURL: user?.profileImageUrl || noImagePlaceholder,
      name: user?.profileImageKey?.originalImage || 'missingImage',
      type: 'image/*',
    },
  ],
});

const PersonalInformation = () => {
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const { user, triggerRefetch } = useAuth();
  const methods = useForm<PersonalInformationForm>({
    mode: 'onChange',
    defaultValues: initialValues(user!),
    resolver: customResolver(validation),
  });

  const { request: updateUser, loading: loadingUpdate } = useAxios({
    url: '/users',
    method: 'PATCH',
    onResponse() {
      triggerRefetch();
    },
    onError: (error) => {
      if ((error.response?.data as any).message.includes('Duplicate')) {
        snackbar.show({ message: MSG_PHONE_NUMBER_DUPLICATE, type: 'error' });
      } else {
        snackbar.show({
          message: error.message,
          type: 'error',
        });
      }
      methods.reset(initialValues(user!));
    },
  });

  const { loading: loadingProfilePicture, request: sendUploadPictureRequest } = useAxios({
    url: '/users/image/upload', // url just for images
    method: 'POST',
    onResponse: () => {
      triggerRefetch();
    },
    onError: () => {
      methods.resetField('profilePicture');
    },
  });

  const { loading: loadingRemovePicture, request: sendRemovePictureRequest } = useAxios({
    url: '/users/image/remove',
    method: 'POST',
    onResponse: () => {
      triggerRefetch();
    },
    onError: () => {
      methods.resetField('profilePicture');
    },
  });

  const handleSubmit = useCallback(
    (data: PersonalInformationForm) => {
      if (
        data.profilePicture[0].blobURL !== noImagePlaceholder &&
        data.profilePicture[0].blobURL !== user?.profileImageKey?.originalImage &&
        methods.getFieldState('profilePicture').isDirty
      ) {
        const { blobURL, name, type } = data.profilePicture[0];

        uploadPicture({ blobURL, name, type }, sendUploadPictureRequest);
      }
      if (
        user?.profileImageKey?.originalImage &&
        data.profilePicture[0].blobURL === noImagePlaceholder
      ) {
        sendRemovePictureRequest({});
      }
      if (
        methods.getFieldState('firstName').isDirty ||
        methods.getFieldState('lastName').isDirty ||
        methods.getFieldState('phoneNumber').isDirty ||
        methods.getFieldState('birthDate').isDirty ||
        methods.getFieldState('address').isDirty
      ) {
        updateUser({
          payload: {
            firstName: data.firstName,
            lastName: data.lastName,
            // TODO
            // birthDate: dayjs(data.birthDate).format('YYYY-MM-DD'),
            address: data.address ? data.address : null,
            phoneNumber: data.phoneNumber
              ? {
                  refferenceNumber: '+381',
                  number: data.phoneNumber?.startsWith('+381')
                    ? data.phoneNumber.substring(4, data.phoneNumber.length)
                    : data.phoneNumber.substring(1, data.phoneNumber.length),
                }
              : null,
          },
        });
      }
      methods.reset({
        firstName: data.firstName,
        lastName: data.lastName,
        phoneNumber: data.phoneNumber,
        birthDate: data.birthDate,
        address: data.address,
        profilePicture: [
          {
            blobURL: data.profilePicture[0].blobURL,
            name: data.profilePicture[0].name || 'missingImage',
            type: data.profilePicture[0].type,
          },
        ],
      });
      setButtonDisabled(true);
    },
    [
      methods,
      sendRemovePictureRequest,
      sendUploadPictureRequest,
      updateUser,
      user?.profileImageKey,
    ],
  );

  useEffect(() => {
    if (loadingProfilePicture || loadingRemovePicture || loadingUpdate)
      OverlaySpinner.show('.panel');
    else OverlaySpinner.hide('.panel');
  }, [loadingProfilePicture, loadingRemovePicture, loadingUpdate]);

  useEffect(() => {
    setButtonDisabled(!methods.formState.isDirty || !methods.formState.isValid);
  }, [methods.formState.isDirty, methods.formState.isValid]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)} className='personal-info' key={user?.id}>
        <div className='personal-info__image-div'>
          <FieldWrapper<ImageUploadProps>
            fieldComponent={ImageUpload}
            name='profilePicture'
            isProfilePicture={true}
            dropzoneOptions={dropzoneConfig}
          />
          <p>
            <span>Tip:</span> Choose an image where your face is recognizable - .JPG .GIF .PNG (Max
            20MB)
          </p>
        </div>
        <div className='personal-info__form'>
          <div className='personal-info__wrapper'>
            <FieldWrapper<InputProps>
              fieldComponent={Input}
              name='firstName'
              label='First name'
              asterix
            />
            <FieldWrapper<InputProps>
              fieldComponent={Input}
              name='lastName'
              label='Last name'
              asterix
            />
            <FieldWrapper<InputProps>
              fieldComponent={Input}
              name='email'
              label='Email'
              disabled
              asterix
            />
            <FieldWrapper<InputProps>
              fieldComponent={Input}
              name='phoneNumber'
              label='Phone number'
            />
            <FieldWrapper<InputProps> fieldComponent={Input} name='address' label='Address' />
          </div>
          <Button
            type='submit'
            loading={loadingUpdate || loadingProfilePicture || loadingRemovePicture}
            disabled={buttonDisabled}
          >
            Save
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default PersonalInformation;

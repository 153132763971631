import classNames from 'classnames';
import React, {
  forwardRef,
  ForwardRefRenderFunction,
  ReactElement,
  useImperativeHandle,
} from 'react';
import { DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone';

import { EditIcon, UploadIcon } from 'assets';
import { Button } from 'components';

import { validateFiles } from './utils';

import './Dropzone.styles.scss';

type DropzoneProps = {
  value: UploadFile[];
  onAccept: (files: File[]) => void;
  message?: string;
  onReject?: (rejectedFiles: FileRejection[]) => void;
  dropzoneOptions?: DropzoneOptions;
  className?: string;
  content?: ReactElement;
  activeContent?: ReactElement;
  isVisible?: boolean;
  onBlur?: () => void;
};

type UploadFileType = Pick<File, 'name' | 'type'>;

export type UploadFile = { blobURL: string } & UploadFileType;

export type OpenFileBrowser = {
  open: () => void;
};

const Dropzone: ForwardRefRenderFunction<OpenFileBrowser, DropzoneProps> = (props, ref) => {
  const {
    onReject,
    onAccept,
    message,
    dropzoneOptions,
    value,
    className,
    content,
    activeContent,
    isVisible = true,
    onBlur,
  } = props;
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    ...dropzoneOptions,
    onFileDialogCancel: () => {
      onBlur?.();
    },
    onDragLeave: () => {
      onBlur?.();
    },
    onDrop: (acceptedFiles, rejectedFiles) => {
      const files = validateFiles(acceptedFiles, value, dropzoneOptions);

      if (
        dropzoneOptions?.maxFiles !== 1 &&
        dropzoneOptions?.multiple &&
        files.acceptedFiles.length
      ) {
        onAccept(files.acceptedFiles);
      } else {
        onAccept(acceptedFiles);
        return;
      }

      if (onReject && (files.rejectedFiles.length || rejectedFiles.length)) {
        onReject([...files.rejectedFiles, ...rejectedFiles]);
      }
    },
  });

  const dragWrapperClassNames = classNames(
    'dropzone-container__wrapper',
    { [`${className}__wrapper`]: className },
    {
      [`${className}__wrapper--active`]: isDragActive && className,
    },
  );

  useImperativeHandle(
    ref,
    () => ({
      open,
    }),
    [open],
  );

  const defaultDragContent = (
    <>
      <p>{message}</p>
      <UploadIcon />
      <Button type='button' className='dropzone-container__button'>
        <EditIcon />
      </Button>
    </>
  );
  const dragContent = content || defaultDragContent;
  const dragActiveContent = activeContent || dragContent;

  return isVisible ? (
    <div className={classNames('dropzone-container', className)}>
      <div {...getRootProps({ className: dragWrapperClassNames })}>
        <input {...getInputProps()} />
        {isDragActive ? dragActiveContent : dragContent}
      </div>
    </div>
  ) : null;
};

export default forwardRef(Dropzone);

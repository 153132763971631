import { yupResolver } from '@hookform/resolvers/yup';
import { UploadFile } from 'components/Dropzone';
import { Modal } from 'components/Modal';
import { Multiplier } from 'models/Multiplier';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { getFileBlob } from 'utils/getFileBlob';
import { getFilename } from 'utils/getFileName';
import { MSG_REQUIRED } from 'validation';
import * as Yup from 'yup';
import { DocumentForm } from './components';
import { Button } from 'components/Button';
import { DocumentFormValues } from '../../types/DocumentFormValues';
import { EmployeeDocument } from 'models/EmployeeDocument';
import DateService from 'services/Date.service';
import { isAfter } from 'date-fns';

import './DocumentModal.styles.scss';

type DocumentModaProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  onSubmit: (formDate: FormData) => void;
  userId: string;
  document?: EmployeeDocument;
  multiplierDisabled?: boolean;
  multipliers: Multiplier[];
  isEditMode?: boolean;
};

const validation = Yup.object().shape({
  type: Yup.mixed<
    'Employent contract' | 'Fixed- term employment contract' | 'Annex' | 'Internship contract'
  >().required(MSG_REQUIRED),
  file: Yup.mixed<UploadFile[]>()
    .required(MSG_REQUIRED)
    .test('file', MSG_REQUIRED, (value) => value.length === 1),

  startDate: Yup.mixed<string | Date>().required(MSG_REQUIRED),
  endDate: Yup.mixed<string | Date>().when('noEndDate', {
    is: false,
    then: () =>
      Yup.mixed<string | Date>()
        .required(MSG_REQUIRED)
        .when('startDate', (startDate, schema) =>
          schema.test('is not before start', 'End date must be after start date', (value) => {
            if (!startDate[0]) return true;
            const start = new Date(startDate[0]);
            const end = new Date(value);
            const isAfterVal = isAfter(end, start);
            return isAfterVal;
          }),
        ),
    otherwise: () => Yup.mixed<Date | string>().optional(),
  }),

  noEndDate: Yup.boolean().optional(),
  netSalaryAmount: Yup.number().min(-1, 'Number must be non-negative').required(MSG_REQUIRED),
  currency: Yup.mixed<'RSD' | 'USD' | 'EUR'>().required(MSG_REQUIRED),
  multiplierId: Yup.string().required(MSG_REQUIRED),
  note: Yup.string().optional().max(150, 'Description must be at most 150 characters'),
});

const createDefaultValues = (document?: EmployeeDocument): Partial<DocumentFormValues> => {
  return !document
    ? ({
        currency: 'RSD',
        file: undefined,
        noEndDate: false,
      } as Partial<DocumentFormValues>)
    : ({
        ...document,
        startDate: new Date(document.startDate),
        endDate: document?.endDate ? new Date(document.endDate) : undefined,
        noEndDate: !document?.endDate,
        file: [
          {
            name: getFilename(document.awsKey || ''),
            blobURL: document.awsKey,
            type: 'application/pdf',
          },
        ],
        netSalaryAmount: document.netSalary?.amount,
        currency: document.netSalary?.currency,
        note: document.note || '',
      } as Partial<DocumentFormValues>);
};

const DocumentModal: FC<DocumentModaProps> = (props) => {
  const {
    isOpen,
    onRequestClose,
    onSubmit,
    document,
    multiplierDisabled,
    multipliers,
    isEditMode,
  } = props;

  const methods = useForm<any>({
    mode: 'onTouched',
    resolver: yupResolver(validation),
    defaultValues: createDefaultValues(document),
    shouldUnregister: true,
  });

  useEffect(() => {
    methods.reset(createDefaultValues(document));
  }, [document, methods, isOpen]);

  const submitDocument = useCallback(
    async (v: DocumentFormValues) => {
      const formData = new FormData();
      formData.set('file', await getFileBlob(v.file[0]));
      formData.set('type', v.type);
      formData.set('startDate', DateService.formatDate(v.startDate, 'yyyy-MM-dd'));
      if (!v.noEndDate && v.endDate) {
        formData.set('endDate', DateService.formatDate(v.endDate, 'yyyy-MM-dd'));
      }
      if (!document) {
        formData.set('netSalaryAmount', String(v.netSalaryAmount));
        formData.set('currency', String(v.currency));
      } else {
        formData.set('netSalary[amount]', String(v.netSalaryAmount));
        formData.set('netSalary[currency]', String(v.currency));
      }
      formData.set('multiplierId', v.multiplierId);
      if (v.note) formData.set('note', v.note);

      return onSubmit(formData);
    },
    [document, methods],
  );

  const formValid = useMemo(
    () =>
      methods.formState.submitCount > 0
        ? Object.keys(methods.formState.errors).filter((errorName) => errorName !== 'root')
            .length === 0
        : methods.formState.isValid,

    [methods.formState],
  );

  const onClose = useCallback(() => {
    onRequestClose();
  }, [onRequestClose, methods]);

  const noEndDate = methods.getValues('noEndDate');

  useEffect(() => {
    if (noEndDate) {
      methods.clearErrors('endDate');
      methods.resetField('endDate');
    }
  }, [noEndDate, methods.clearErrors]);

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className='document-modal' hasCloseIcon>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(submitDocument)}>
          <h2>{!isEditMode ? 'Add Document' : 'Edit Document'}</h2>

          <DocumentForm
            methods={methods}
            multipliers={multipliers}
            multiplierDisabled={multiplierDisabled}
          />
          <div className='document-modal__actions'>
            <Button variant='outline' onClick={onRequestClose}>
              Cancel
            </Button>
            <Button
              type='submit'
              loading={methods.formState.isSubmitting}
              disabled={!formValid || (isEditMode ? !methods.formState.isDirty : false)}
            >
              {!isEditMode ? 'Add' : 'Edit'}
            </Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default DocumentModal;

import classNames from 'classnames';
import React, { FC, useCallback, useState } from 'react';
import { DropzoneOptions } from 'react-dropzone';

import { CloseIcon } from 'assets';
import { Button, Dropzone, Modal } from 'components';
import { useFileUpload } from 'hooks';

import noImagePlaceholder from 'assets/No-Image-Placeholder.svg.png';
import { UploadFile } from 'components/Dropzone/Dropzone.component';

import './ImageUpload.styles.scss';

export type ImageUploadProps = {
  value: UploadFile[];
  onChange: (files: UploadFile[]) => void;
  message?: string;
  hasError?: boolean;
  helperText?: string;
  dropzoneOptions?: DropzoneOptions;
  className?: string;
  isProfilePicture?: boolean;
};

const ImageUpload: FC<ImageUploadProps> = (props) => {
  const {
    value = [],
    onChange,
    message = "Drag 'n' drop some images here or click to open dialog",
    helperText,
    dropzoneOptions,
    className,
    isProfilePicture,
  } = props;
  const [modalIsOpen, setIsOpen] = useState(false);
  const { handleAccept, handleReject, handleRemove, errorMessages } = useFileUpload({
    multiple: false,
    onChange,
    value,
    isProfilePicture,
  });

  const imageUploadClassnames = classNames('image-upload-container', className);
  const dropzoneClassnames = classNames(
    `image-upload-container__dropzone${value?.length ? '--image-selected' : ''}`,
  );

  const handleOpenModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const url = props.value[0].blobURL;
  return (
    <div className={imageUploadClassnames}>
      <Dropzone
        onAccept={handleAccept}
        dropzoneOptions={dropzoneOptions}
        message={message}
        className={dropzoneClassnames}
        value={value}
        onReject={handleReject}
      />
      {value.length &&
        props.value?.map(({ blobURL }: { blobURL: string }) => (
          <div key={blobURL} className='image-upload-container__image-wrapper'>
            <img src={blobURL || url} alt={blobURL} />{' '}
            {blobURL !== noImagePlaceholder && isProfilePicture && (
              <Button
                onClick={() => handleOpenModal()}
                className='image-upload-container__button'
                type='button'
              >
                <CloseIcon />
              </Button>
            )}
            {modalIsOpen && isProfilePicture && (
              <Modal isOpen={modalIsOpen} onRequestClose={handleCloseModal} hasCloseIcon>
                <h2 className='image-upload-container__modal__title'>
                  Are you sure you want to remove your profile image?
                </h2>
                <div className='image-upload-container__modal__buttons'>
                  <Button
                    onClick={() => {
                      handleCloseModal();
                    }}
                    className='image-upload-container__modal__buttons__button image-upload-container__modal__buttons__button--cancel'
                  >
                    <span>Cancel</span>
                  </Button>
                  <Button
                    onClick={() => {
                      handleRemove(blobURL);
                      handleCloseModal();
                    }}
                    className='image-upload-container__modal__buttons__button'
                  >
                    <span>Confirm</span>
                  </Button>
                </div>
              </Modal>
            )}
          </div>
        ))}

      {errorMessages.length > 0 ? (
        errorMessages.map((errorMessage, index) => <small key={index}>{errorMessage}</small>)
      ) : (
        <small>{helperText || '‎ '}</small>
      )}
    </div>
  );
};

export default ImageUpload;

import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'components/Button';
import { FieldWrapper } from 'components/FieldWrapper';
import FileInput, { FileInputProps } from 'components/FileInput/FileInput.component';
import Input, { InputProps } from 'components/Input/Input.component';
import { Modal } from 'components/Modal';
import { useAxios } from 'hooks';
import React, { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { MSG_REQUIRED } from 'validation';
import * as Yup from 'yup';

import './AddDocuments.styles.scss';
import { EmployeeDocument } from 'models/EmployeeDocument';

type AddDocumentsModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  userId?: string;
  file?: EmployeeDocument;
  onChangeDocumentList?: () => void;
};

type DocumentForm = {
  type: string;
  file: File;
  startDate: Date | string;
  endDate: Date | string | undefined;
  grossValue: number;
  currency: string;
  noEndDate: boolean | undefined;
  note: string | undefined;
};

const documentOptions = [
  { value: 'Employment contract', label: 'Employment contract' },
  {
    value: 'Fixed-term employment contract',
    label: 'Fixed-term employment contract',
  },
  { value: 'Annex', label: 'Annex' },
  { value: 'Internship contract', label: 'Internship contract (agency)' },
];
const currencyOptions = [
  { value: 'RSD', label: 'RSD' },
  { value: 'EUR', label: 'EUR' },
  { value: 'USD', label: 'USD' },
];

const validation = Yup.object().shape({
  type: Yup.string().required(MSG_REQUIRED),
  file: Yup.mixed<File>()
    .required(MSG_REQUIRED)
    .test('fileSize', 'File size must be smaller than 500KB', (value) => {
      if (value && value.size) {
        return value.size <= 50000;
      }
      return true;
    }),
  startDate: Yup.mixed<Date | string>().required(MSG_REQUIRED),
  endDate: Yup.mixed<Date | string>().when('noEndDate', {
    is: false,
    then: () => Yup.string().required(MSG_REQUIRED),
  }),
  grossValue: Yup.number().required(MSG_REQUIRED),
  currency: Yup.string().required(MSG_REQUIRED),
  note: Yup.string(),
  noEndDate: Yup.boolean(),
});

const shortenFileName = (name: string) => {
  if (name.length > 12) {
    return name.slice(0, 11).concat('.pdf');
  } else return name;
};

const AddDocumentsModal = (props: AddDocumentsModalProps) => {
  const { isOpen, onRequestClose, userId, file, onChangeDocumentList } = props;
  const [fieldFile, setFieldFile] = useState<File>();

  const { watch, ...methods } = useForm<any>({
    mode: 'onTouched',
    resolver: yupResolver(validation),
    defaultValues: {
      startDate: file ? new Date(file.startDate) : undefined,
      endDate: file?.endDate ? new Date(file.endDate) : undefined,
      type: file?.type || undefined,
      grossValue: file?.grossSalary.amount || undefined,
      currency: file?.grossSalary.currency,
      noEndDate: file && !file?.endDate ? true : false,
      note: file?.note || undefined,
    },
  });

  const { request: addDocument, loading: loadingCreate } = useAxios({
    url: `files/user/${userId}`,
    method: 'POST',
    onResponse: () => {
      onRequestClose();
      onChangeDocumentList?.();
    },
  });

  const { request: updateDocument, loading: loadingUpdate } = useAxios({
    url: `files/${file?._id}`,
    method: 'PUT',
    onResponse: () => {
      onRequestClose();
      onChangeDocumentList?.();
    },
  });

  const handleSubmit = useCallback(
    (ev: DocumentForm) => {
      const formData = new FormData();

      formData.set('currency', ev.currency);
      formData.set('grossValue', String(ev.grossValue));
      // TODO
      // formData.set('startDate', dayjs(ev.startDate).format('YYYY-MM-DD'));
      formData.set('type', ev.type);
      formData.set('file', fieldFile!);

      // TODO
      // if (ev.endDate !== undefined) formData.set('endDate', dayjs(ev.endDate).format('YYYY-MM-DD'));
      if (ev.note) {
        formData.set('note', ev.note);
      }
      if (file !== undefined) {
        updateDocument({ payload: formData });
      } else {
        addDocument({ payload: formData });
      }
    },
    [addDocument, fieldFile, file, updateDocument],
  );

  const handleFileChange = useCallback((files: File[]) => setFieldFile(files[0]), []);

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} hasCloseIcon>
      <div className='add-documents'>
        <h1 className='add-documents__title'>{file ? 'Modify document' : 'Add document'}</h1>
        <FormProvider watch={watch} {...methods}>
          <form className='add-documents__form' onSubmit={methods.handleSubmit(handleSubmit)}>
            <div className='add-documents__document'>
              <div className='add-documents__type'>
                <label>Document type</label>
                <select {...methods.register('type')} className='add-documents__type__select'>
                  {documentOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className='add-documents__file'>
                <label>Document</label>
                <FieldWrapper<FileInputProps>
                  name='file'
                  fieldComponent={FileInput}
                  handleChange={handleFileChange}
                  accept='.pdf'
                  fileName={fieldFile && shortenFileName(fieldFile.name)}
                  fileUrl={
                    fieldFile
                      ? URL.createObjectURL(
                          new Blob([fieldFile], {
                            type: fieldFile.type,
                          }),
                        )
                      : undefined
                  }
                  trigger={
                    <Button type='button' className='add-documents__file__trigger'>
                      Upload document
                    </Button>
                  }
                />
              </div>
            </div>
            <div className='add-documents__date'></div>
            <div className='add-documents__salary'>
              <label>Gross salary</label>
              <div className='add-documents__salary__inputs'>
                <FieldWrapper<InputProps>
                  name='grossValue'
                  placeholder='Gross salary'
                  fieldComponent={Input}
                  type='number'
                />
                <select {...methods.register('currency')} className='add-documents__salary__select'>
                  {currencyOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className='add-documents__note'>
              <label>Note</label>
              <textarea {...methods.register('note')} className='add-documents__textarea' />
            </div>
            <Button
              loading={loadingCreate || loadingUpdate}
              className='add-documents__submit-button'
              type='submit'
            >
              Proceed
            </Button>
          </form>
        </FormProvider>
      </div>
    </Modal>
  );
};

export default AddDocumentsModal;

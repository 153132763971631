export type Pagination = {
  totalItems: number;
  currentPage: number;
  totalPages: number;
  pageSize: number;
};

export enum LinkVariant {
  Button = 'button',
  Text = 'text',
}

export type PaginationType<T> = {
  items: T[];
} & Pagination;

export enum SortingOrder {
  UNSORTED = 0,
  ASCENDING = 1,
  DESCENDING = -1,
}
export type ErrorType = {
  message: string;
  error: string;
  statusCode: number;
};

import { SortingOrder } from 'models/types';

const toggleSortingOrder = (sortByValue: number) => {
  return sortByValue == SortingOrder.UNSORTED
    ? SortingOrder.ASCENDING
    : sortByValue == SortingOrder.ASCENDING
      ? SortingOrder.DESCENDING
      : SortingOrder.UNSORTED;
};

export { toggleSortingOrder };

import { AddIcon, BackArrow } from 'assets';
import { Button, GeneralVacationModal, NonWorkingDaysTable } from 'components';
import { APP_URI } from 'config';
import { useModalState } from 'hooks';
import { TGeneralVacation } from 'models/GeneralVacation';
import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import './NonWorkingDaysPage.styles.scss';
import { GeneralVacationsTable } from './components/GeneralVacationsTable';
import { PageLayout } from 'components/layout/PageLayout';
import { PageHeader } from 'components/layout/PageHeader';

const NonWorkingDaysPage = () => {
  const [selectedGeneralVacation, setSelectedGeneralVacation] = useState<
    TGeneralVacation | undefined
  >();

  const {
    isOpen: isGeneralVacationModalOpen,
    openModal: openGeneralVacationModal,
    closeModal: closeGeneralVacationModal,
  } = useModalState();

  const closeGeneralVacation = useCallback(() => {
    closeGeneralVacationModal();
    setSelectedGeneralVacation(undefined);
  }, [closeGeneralVacationModal]);

  const [refreshKey, setRefreshKey] = useState(0);

  return (
    <PageLayout className='non-working-days'>
      <PageHeader
        title={
          <Link to={APP_URI.timeoffs} className='non-working-days__link'>
            <BackArrow />
            Go back
          </Link>
        }
        asideFromTitle={
          <Button
            onClick={() => {
              openGeneralVacationModal();
            }}
            className='non-working-days__add-button'
          >
            <AddIcon />
            Add general vacation
          </Button>
        }
      />

      {isGeneralVacationModalOpen && (
        <GeneralVacationModal
          onClose={closeGeneralVacation}
          isOpen={isGeneralVacationModalOpen}
          onSuccess={() => {
            setRefreshKey((prevKey) => prevKey + 1); // Increment to trigger refresh
          }}
          generalVacation={selectedGeneralVacation}
          isEditMode={false}
        />
      )}

      <GeneralVacationsTable vacationAddedRefreshKey={refreshKey} />

      <NonWorkingDaysTable />
    </PageLayout>
  );
};

export default NonWorkingDaysPage;

import React from 'react';
import {
  CalendarIcon,
  Employee,
  Monitor,
  MyEmployeesIcon,
  Notification,
  SettingsIcon,
  User,
} from 'assets';
import { APP_URI } from 'config';
import { UserRole } from 'enums';

export const getSidebarMenuItems = (userRole?: UserRole) => {
  const commonItems = [
    {
      display: 'Profile',
      icon: <User className='image' />,
      to: APP_URI.profile,
    },
  ];

  const userItems = [
    {
      display: 'Documents',
      icon: <Monitor className='image' />,
      to: APP_URI.documents,
    },
    {
      display: 'Days off',
      icon: <CalendarIcon className='image' />,
      to: APP_URI.daysoff,
    },
  ];

  const adminItems = [
    {
      display: 'Dashboard ',
      icon: <Monitor className='image' />,
      to: APP_URI.dashboard,
    },
    {
      display: 'Reports',
      icon: <Notification className='image' />,
      to: APP_URI.reports,
    },
    {
      display: 'Time offs',
      icon: <CalendarIcon className='image' />,
      to: APP_URI.timeoffs,
    },
    {
      display: 'My employees',
      icon: <MyEmployeesIcon className='image' />,
      to: APP_URI.employees,
    },
    {
      display: 'Roles',
      icon: <Monitor className='image' />,
      to: '/roles',
    },
    {
      display: 'Settings',
      icon: <SettingsIcon className='image' />,
      to: '/settings',
    },
  ];

  const sidebarNavItems =
    userRole === UserRole.ADMIN
      ? [
          ...adminItems.slice(0, adminItems.length - 1),
          ...commonItems,
          adminItems[adminItems.length - 1],
        ]
      : [...userItems, ...commonItems];

  return sidebarNavItems;
};

import { useNavigate, useSearchParams } from 'react-router-dom';
import Input, { InputProps } from 'components/Input/Input.component';
import { AuthContainer } from 'components/AuthContainer';
import { FormProvider, useForm } from 'react-hook-form';
import { FieldWrapper } from 'components/FieldWrapper';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'components/Button';
import React, { useCallback } from 'react';
import { PasswordField } from 'components';
import jwt_decode from 'jwt-decode';
import { snackbar } from 'modules';
import { APP_URI } from 'config';
import { useAxios } from 'hooks';
import * as Yup from 'yup';
import {
  LOWERCASE_REGEX,
  MSG_REQUIRED,
  NUMBER_REGEX,
  SPECIAL_CHARACTER_REGEX,
  UPPERCASE_REGEX,
} from 'validation';

import './SetPassword.styles.scss';

type SetPasswordType = {
  password: string;
  confirmPassword: string;
};

const validation = Yup.object().shape({
  password: Yup.string()
    .required(MSG_REQUIRED)
    .min(8, 'Password must be at least 8 characters')
    .matches(LOWERCASE_REGEX, 'Password must contain at least one lowercase letter')
    .matches(UPPERCASE_REGEX, 'Password must contain at least one uppercase letter')
    .matches(SPECIAL_CHARACTER_REGEX, 'Password must contain at least one special character')
    .matches(NUMBER_REGEX, 'Password must contain at least one number'),
  confirmPassword: Yup.string()
    .required(MSG_REQUIRED)
    .oneOf([Yup.ref('password'), ''], 'Passwords must match')
    .min(8, 'Password must be at least 8 characters')
    .matches(LOWERCASE_REGEX, 'Password must contain at least one lowercase letter')
    .matches(UPPERCASE_REGEX, 'Password must contain at least one uppercase letter')
    .matches(SPECIAL_CHARACTER_REGEX, 'Password must contain at least one special character')
    .matches(NUMBER_REGEX, 'Password must contain at least one number'),
});

const SetPassword = () => {
  const [searchParams] = useSearchParams();
  const hash = decodeURI(searchParams.get('token') || '');
  const navigate = useNavigate();
  const decoded: { email: string; firstName: string; lastName: string } = jwt_decode(hash);

  const methods = useForm<SetPasswordType>({
    resolver: yupResolver(validation),
    mode: 'onSubmit',
  });

  const { loading, request: register } = useAxios({
    url: '/users/register',
    method: 'POST',
    onResponse: () => {
      snackbar.show({
        message: 'Successful registration. Please log in now.',
        type: 'success',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'top',
        },
      });
      navigate(APP_URI.login);
    },
    onError: () => {
      snackbar.show({
        message: 'Error occurred, please contact administrator.',
        type: 'error',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'top',
        },
      });
    },
  });

  const handleSubmit = useCallback(
    (data: { password: string; confirmPassword: string }) => {
      register({ payload: { hash, newPassword: data.password } });
    },
    [hash, register],
  );

  return (
    <div className='set-password'>
      <AuthContainer title='Set password'>
        <FormProvider {...methods}>
          <form className='set-password__container' onSubmit={methods.handleSubmit(handleSubmit)}>
            <Input
              label='Name'
              value={`${decoded.firstName} ${decoded.lastName}`}
              disabled
              className='set-password__container__input__disabled'
            />
            <Input
              label='Email'
              value={decoded.email}
              disabled
              className='set-password__container__input__disabled'
            />
            <FieldWrapper<InputProps>
              fieldComponent={PasswordField}
              name='password'
              label='Password'
              type='password'
              className='input-container--fade-in--faster'
            />
            <FieldWrapper<InputProps>
              fieldComponent={PasswordField}
              name='confirmPassword'
              label='Confirm password'
              type='password'
              className='input-container--fade-in--faster'
            />
            <Button
              type='submit'
              className='set-password__container__register-button'
              loading={loading}
              disabled={
                methods.watch('confirmPassword') === undefined ||
                methods.watch('password') === undefined
              }
            >
              Register
            </Button>
          </form>
        </FormProvider>
      </AuthContainer>
    </div>
  );
};

export default SetPassword;

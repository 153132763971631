import React from 'react';
import { EmployeeCard } from 'components/EmployeeCard';
import './EmployeesLayout.styles.scss';
import { User } from 'models/User';

const EmployeesLayout = (props: { employees: User[] }) => {
  const { employees } = props;

  return (
    <div className='nce-employees-layout__grid'>
      {employees.map((employee: User) => (
        <EmployeeCard
          key={employee.id}
          employeeId={employee.id}
          firstName={employee.firstName!}
          lastName={employee.lastName!}
          imageUrl={employee.profileImageUrl}
        />
      ))}
    </div>
  );
};
export default EmployeesLayout;

/* eslint-disable no-undef */
import classNames from 'classnames';
import { TableRowMessage } from 'components/TableRowMessage';
import './Table.styles.scss';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

export type TableColumnItem<T> = {
  columnName?: string;
  columnLabel: string | JSX.Element;
  prepareCell: (data: T) => null | number | string | JSX.Element; // Type definition for formatting and preparing data for a specific table column
};

export type TableProps<T> = {
  className?: string;
  data: T[] | undefined;
  prepareData: TableColumnItem<T>[]; // An array of objects with prepareCell function that specify how to format each cell's data
  noDataMessage?: string;
  errorMessage?: string;
  isError?: boolean;
  isLoading?: boolean;
  hasScrollbar?: boolean;
  onTableRowClick?: (data: T) => void;
  isRowClickable?: boolean;
};

const Table = <T extends Record<string, any>>(props: TableProps<T>) => {
  const {
    className,
    data,
    prepareData,
    noDataMessage = 'No data...',
    isError = false,
    errorMessage,
    isLoading,
    onTableRowClick,
    isRowClickable = false,
  } = props;

  const classes = classNames('nce-table', className);

  const tableCellClasses = useCallback(
    (columnName?: string) =>
      classNames({
        [`${className || 'nce-table'}__${columnName}`]: !!columnName,
      }),
    [className],
  );

  const isNoData = isError || data === undefined || data.length === 0;

  const columns = useMemo(() => {
    return prepareData.map((column, index) => (
      <th className={tableCellClasses(column.columnName)} key={index}>
        {column.columnLabel}
      </th>
    ));
  }, [prepareData, tableCellClasses]);

  const navigate = useNavigate();

  return (
    <table className={classes}>
      <thead>
        <tr>{columns}</tr>
      </thead>

      <tbody>
        {isLoading || isNoData ? (
          <TableRowMessage
            isLoading={isLoading}
            isError={isError}
            message={isError ? errorMessage : noDataMessage}
            className='nce-table__no-data'
            colSpan={columns.length}
          />
        ) : (
          data?.map((row, rowIndex) => (
            <tr
              key={rowIndex}
              onClick={() => {
                if (isRowClickable) {
                  navigate(`/employees/manage-employees/${row.id}`);
                }
              }}
            >
              {prepareData.map((prepare, columnIndex) => {
                const data = prepare.prepareCell(row);
                return (
                  <td className={tableCellClasses(prepare.columnName)} key={columnIndex}>
                    {typeof data === 'string' ? <p>{data}</p> : data}
                  </td>
                );
              })}
            </tr>
          ))
        )}
      </tbody>
    </table>
  );
};

export default Table;

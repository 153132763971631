import React, { FC, useEffect, useState } from 'react';

import { getYear } from 'date-fns';
import { SectionBlock } from 'components/layout/SectionBlock';
import Table from 'components/Table';
import TablePagination from 'components/TablePagination';
import usePaginatedData from 'hooks/usePaginatedData';
import { Vacation } from 'models/Vacation';
import { YearPicker } from 'components';
import { EmployeeDocument } from 'models/EmployeeDocument';
import { useVacationsTableColumns } from 'pages/DaysOffPage/hooks/useVacationsTableColumns';
import { SortingOrder } from 'models/types';

import './VacationsTable.styles.scss';

type VacationsTableProps = {
  startYear?: number;
};

const VacationsTable: FC<VacationsTableProps> = ({ startYear }) => {
  const {
    data: userVacations,
    loading: loadingVacations,
    pagination,
    params,
    setParams,
    changePageNumber,
    onYearChange,
  } = usePaginatedData<Vacation>(
    {
      sort: { startDate: -1 },
      limit: 10,
      page: 1,
      filter: { year: getYear(new Date()) },
    },
    '/vacations',
  );

  const { firstYear } = usePaginatedData<EmployeeDocument>(
    {
      sort: { startDate: 1 },
      limit: 1,
    },
    '/files',
    true,
  );

  const [sortByStartDate, setSortByStartDate] = useState(SortingOrder.DESCENDING);

  const handleSortByStartDate = () => {
    setSortByStartDate(-sortByStartDate);
  };

  useEffect(() => {
    setParams((prev) => ({ ...prev, page: 1, sort: { startDate: sortByStartDate } }));
  }, [sortByStartDate]);

  const vacationsTableColumns = useVacationsTableColumns(handleSortByStartDate, sortByStartDate);

  return (
    <SectionBlock
      title='Vacations'
      loading={loadingVacations}
      className='vacations-table'
      asideFromTitle={
        <YearPicker
          year={params?.filter?.year || getYear(new Date())}
          onYearChange={onYearChange}
          maxYear={getYear(new Date()) + 1}
          minYear={startYear}
        />
      }
    >
      <div className='vacations-table__table-container'>
        <Table
          data={userVacations}
          prepareData={vacationsTableColumns}
          noDataMessage="Here, you'll find all your approved vacations and related documents once you submit your first vacation request."
        />
      </div>

      {pagination && (
        <TablePagination
          totalPages={pagination?.totalPages}
          currentPage={pagination?.currentPage}
          totalItems={pagination?.totalItems}
          pageSize={pagination?.pageSize}
          onPageChange={changePageNumber}
        />
      )}
    </SectionBlock>
  );
};
export default VacationsTable;

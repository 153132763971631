import React from 'react';
import ReactDatePicker from 'react-datepicker';
import { CalendarIcon } from 'assets';
import { DEAULT_FORMAT } from 'services/Date.service';

import './DatePicker.styles.scss';
import classNames from 'classnames';

export type DatePickerProps = {
  label: React.ReactNode;
  asterix: boolean;
  value: Date;
  onChange: (date: Date) => void;
  disabled?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
  hasError?: boolean;
  errorMessage?: string;
  minDate?: Date;
};

const DatePicker: React.FC<DatePickerProps> = ({
  label,
  asterix,
  value,
  onChange,
  disabled,
  onBlur,
  onFocus,
  hasError,
  errorMessage,
  minDate,
}) => {
  const classes = classNames('ne-datepicker', {
    'ne-datepicker--disabled': disabled,
  });

  return (
    <div className={classes}>
      {label && (
        <label>
          {label}
          {asterix && '*'}
        </label>
      )}
      <ReactDatePicker
        onBlur={onBlur}
        onFocus={onFocus}
        selected={value}
        dateFormat={DEAULT_FORMAT}
        onChange={onChange}
        icon={<CalendarIcon />}
        showIcon
        toggleCalendarOnIconClick
        disabled={disabled}
        minDate={minDate || new Date()}
        onKeyDown={(e) => {
          e.preventDefault();
        }}
      />
      <small className={classNames('ncoded-small', hasError && 'ncoded-small--invalid-input')}>
        {errorMessage}
      </small>
    </div>
  );
};

export default DatePicker;

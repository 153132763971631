import { User } from 'models/User';
import { httpClient } from 'modules';

async function updateEmployeeNonWorkingDays(employeeId: string, vacationDaysCurrentYear: number) {
  return httpClient.patch<User>(`/users/${employeeId}`, {
    vacationDaysCurrentYear,
  });
}

export default { updateEmployeeNonWorkingDays };

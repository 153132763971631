import React from 'react';
import { User } from 'models/User';
import './SettingsPage.styles.scss';
import { PageLayout } from 'components/layout/PageLayout';
import { PageHeader } from 'components/layout/PageHeader';
import MulitpliersSettings from './components/MulitpliersSettings';

export type MultiplierResponseType = {
  _id?: string;
  name: string;
  value: number;
  users: User[];
};

const SettingsPage = () => {
  // const { request: addMultiplier } = useAxios<AddMultiplierType, ErrorType>({
  //   url: `multipliers`,
  //   method: 'POST',
  //   onResponse: ({ data }) => {
  //     ref.current?.resetFields();
  //     updateTable({ name: data.name, value: data.value, users: [] });
  //     snackbar.show({ message: 'You have successfully added new multiplier.', type: 'success' });
  //   },
  //   onError: (error) => {
  //     snackbar.show({ message: error.response?.data.message || error.message, type: 'error' });
  //   },
  // });

  return (
    <PageLayout>
      <PageHeader title='Settings' />
      <MulitpliersSettings />
    </PageLayout>
  );
};
export default SettingsPage;
